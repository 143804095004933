import {makeStyles} from '@material-ui/core/styles'

export const appBackground =
  'radial-gradient(84.77% 84.77% at 50% 15.23%, #2D83BB 0%, #0F4B73 100%)'

export const useLayoutStyles = makeStyles(() => ({
  cursorPointer: {
    cursor: 'pointer'
  },

  flx: {
    display: 'flex'
  },

  absolute: {position: 'absolute'},
  relative: {position: 'relative'},
  flx1: {flex: 1},
  flxRow: {flexDirection: 'row'},
  flxRowReverse: {flexDirection: 'row-reverse'},
  flxCol: {flexDirection: 'column'},
  flxColReverse: {flexDirection: 'column-reverse'},
  flxWrap: {flexWrap: 'wrap'},
  aifs: {alignItems: 'flex-start'},
  aic: {alignItems: 'center'},
  aife: {alignItems: 'flex-end'},
  jcc: {justifyContent: 'center'},
  jcfe: {justifyContent: 'flex-end'},
  jcsb: {justifyContent: 'space-between'},
  jcsa: {justifyContent: 'space-around'},
  asfs: {alignSelf: 'flex-start'},
  asfe: {alignSelf: 'flex-end'},
  asc: {alignSelf: 'center'},
  ass: {alignSelf: 'stretch'},

  mt1: {
    marginTop: '4px'
  },

  mt2: {
    marginTop: '8px'
  },

  mt3: {
    marginTop: '16px'
  },

  f1: {
    fontSize: '40px'
  },
  f2: {
    fontSize: '32px'
  },
  f3: {
    fontSize: '24px'
  },
  f4: {
    fontSize: '20px'
  },
  f5: {
    fontSize: '16px'
  },
  f6: {
    fontSize: '12px'
  },
  f8: {
    fontSize: '10px'
  },
  f9: {
    fontSize: '8px'
  },
  hidden: {
    visibility: 'hidden'
  }
}))

export const useFontStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500
  }
}))

export const useColorStyles = makeStyles((theme) => ({
  text: {
    color: 'rgba(0, 55, 77, 0.96)'
  },

  textContrast: {
    color: theme.palette.secondary.dark
  },

  fillContrast: {
    fill: 'rgba(255, 255, 255, 0.96)'
  }
}))

export const useComponentStyles = makeStyles(() => ({
  textButton: {
    borderRadius: '4px',
    padding: '7px 10px',
    color: 'rgb(41, 170, 255)',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.2px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  }
}))
