import {GetDropdownSelectedItemArgs} from './types'

export const getDropdownSelectedItem = ({
  items,
  isSubItem,
  selectedItemId,
  itemKey,
  subItemKey
}: GetDropdownSelectedItemArgs) => {
  if (!selectedItemId) {
    return undefined
  }

  for (const item of items) {
    if (item[itemKey] === selectedItemId && !isSubItem) {
      return item
    }

    for (const subItem of item.items) {
      if (subItem[subItemKey] === selectedItemId) {
        return subItem
      }
    }
  }
}
