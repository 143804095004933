import {Typography as TypographyMui} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'
import {TypographyProps} from './types'

const useStyles = makeStyles()((theme) => ({
  gutterAround: {
    padding: theme.spacing(2.5)
  },
  textSecondarySoft: {
    color: theme.palette.text.secondarySoft
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  textPrimarySoft: {
    color: theme.palette.text.primarySoft
  },
  textError: {
    color: theme.palette.error.main
  },
  textSuccessMain: {
    color: theme.palette.success.main
  },
  textDisabled: {
    color: theme.palette.text.disabled
  },
  textErrorContrastText: {
    color: theme.palette.common.white
  },
  breakWords: {
    wordWrap: 'break-word'
  },
  breakLine: {
    wordWrap: 'normal'
  },
  bold: {
    fontWeight: 700
  }
}))

export const Typography: React.FC<TypographyProps> = ({
  children,
  gutterAround = false,
  breakWords = false,
  breakLine = false,
  customColor,
  ...props
}) => {
  const {classes} = useStyles()
  return (
    <TypographyMui
      className={`
        ${customColor ? classes[customColor] : ''}
        ${gutterAround ? classes.gutterAround : ''}
        ${breakWords ? classes.breakWords : ''}
        ${breakLine ? classes.breakLine : ''}
      `}
      {...props}
    >
      {children}
    </TypographyMui>
  )
}
