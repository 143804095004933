import {alpha} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

const MAX_WIDTH = 35 // (35 x 8px = 280px)
const MAX_HEIGHT = 56.75 // (56.75 x 8px = 454px)

const useDropdownStyle = makeStyles()((theme) => ({
  root: {
    minWidth: 160
  },
  mobileRoot: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%'
    }
  },
  checkbox: {
    '&:hover': {
      boxShadow: 'none !important'
    }
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  button: {
    display: 'block',
    textAlign: 'left',
    maxWidth: theme.spacing(MAX_WIDTH)
  },
  textField: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 0 auto',
    minWidth: 160
  },
  textFieldLong: {
    paddingTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: '1 0 auto',
    minWidth: 160
  },
  textFieldLongAndInWidget: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: '1 0 auto',
    minWidth: 160
  },
  popover: {
    backgroundColor: '#00374d',
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    width: theme.spacing(MAX_WIDTH),
    maxHeight: theme.spacing(MAX_HEIGHT)
  },
  popoverOnLight: {
    backgroundColor: '#f7f7f7',
    color: 'rgb(0, 55, 77)',
    marginTop: theme.spacing(2),
    width: theme.spacing(MAX_WIDTH),
    maxHeight: theme.spacing(MAX_HEIGHT)
  },
  searchContainer: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`
  },
  searchInput: {
    color: theme.palette.common.white
  },
  searchInputOnLight: {
    color: 'rgb(0, 55, 77)'
  },
  searchInputUnderline: {
    '&:hover:before': {
      borderColor: [theme.palette.common.white, '!important']
    },
    '&:before': {
      borderColor: theme.palette.common.white
    }
  },
  searchInputUnderlineOnLight: {
    '&:hover:before': {
      borderColor: ['#29aaff', '!important']
    },
    '&:before': {
      borderColor: '#8aa4ad'
    }
  },
  searchInputLabel: {
    color: '#29aaff !important'
  },
  searchInputLabelFocused: {
    color: theme.palette.secondary.light
  },
  searchInputLabelFocusedOnLight: {
    color: '#29aaff'
  },
  inputAdornment: {
    color: '#809ba6',

    '& > *': {
      background: 'none'
    },
    '& > svg': {
      height: '16px',
      width: '16px'
    }
  },
  inputAdornmentOnDark: {
    color: 'inherit'
  },
  menu: {
    maxHeight: 250,
    overflowY: 'scroll'
  },
  menuItem: {
    padding: `${theme.spacing(0.75)} ${theme.spacing(2)} !important`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  item: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#1a4b5f'
    },
    '& p': {
      color: theme.palette.text.secondarySoft
    },
    whiteSpace: 'normal',
    width: '100%'
  },
  itemOnLight: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    '& p': {
      color: theme.palette.text.primarySoft
    },
    whiteSpace: 'normal',
    width: '100%'
  },
  itemOnLightRow: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    '& p': {
      color: theme.palette.text.primary
    },
    whiteSpace: 'normal',
    width: '100%'
  },
  itemDisabledOnLight: {
    backgroundColor: '#c7c7c7'
  },
  itemDisabled: {
    backgroundColor: '#1a4b5f'
  },
  subItem: {
    width: '100%',
    height: 'auto',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#1a4b5f'
    },
    '& p': {
      color: theme.palette.text.secondarySoft
    },
    whiteSpace: 'normal'
  },
  subItemOnLight: {
    width: '100%',
    height: 'auto',
    minHeight: '44px',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    },
    '& p': {
      color: theme.palette.text.primarySoft
    },
    whiteSpace: 'normal'
  },
  arrow: {
    marginLeft: theme.spacing(2),
    color: alpha(theme.palette.secondary.dark, 0.96)
  },
  arrowWhite: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper
  },
  dark: {
    background: alpha(theme.palette.common.black, 0.4),
    '&:hover': {
      background: alpha(theme.palette.common.black, 0.2)
    },
    '&:active': {
      background: alpha(theme.palette.common.black, 0.4)
    },
    '& $arrow': {
      color: theme.palette.text.secondary
    }
  },
  fontSize: {
    fontSize: 14
  },
  selected: {
    backgroundColor: '#335f70 !important',
    '& span': {border: 'none'},
    '& div': {border: 'none'},
    '&:hover': {
      backgroundColor: ['#1a4b5f', '!important']
    }
  },
  selectedOnLight: {
    backgroundColor: '#f0f0f0 !important',
    '& span': {border: 'none'},
    '& div': {border: 'none'},
    '&:hover': {
      backgroundColor: ['#f0f0f0', '!important']
    }
  }
}))

export default useDropdownStyle
