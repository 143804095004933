/* eslint-disable complexity */
import {Typography, useNotification} from '@hconnect/uikit'
import {Card, CircularProgress, useMediaQuery, useTheme} from '@material-ui/core'
import {
  Paper,
  Box,
  Grid,
  Hidden,
  Stack,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableBody
} from '@mui/material'
import parsePhoneNumberFromString, {CountryCode} from 'libphonenumber-js'
import {isEmpty, some, values, isEqual, uniq, first, partial, partialRight} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useForm, useFieldArray, FormProvider, Controller, SubmitHandler} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import distributedCacheLoadingImage from '../../../Assets/distributedCacheLoading.svg'
import noValidMaterialImage from '../../../Assets/noValidMaterials/no-delivery-placeholder.svg'
import {trackEvent} from '../../../common/analytics'
import {usePersistedContact} from '../../../common/react-query/hooks/queries/usePersistedContact'
import {usePersistedOrderRequests} from '../../../common/react-query/hooks/queries/usePersistedOrderRequests'
import {invalidateHubQuery} from '../../../common/react-query/QueryProvider'
import {useOrderIntakeStyles} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {SiteDropdown} from '../../../OrderIntake/components/SiteDropdown'
import {useFeaturesState} from '../../../Organisms/Features'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {
  checkEmptyTimeZone,
  generateGuid,
  getCurrentTimeStamp,
  getDefaultMaterialOptions,
  getTodaysCutOffTime,
  isCurrentTimeAfterCutOff,
  isDateAvailable
} from '../../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {AddAnotherButton} from '../../components/AddAnotherButton'
import {
  PersistedMaterialsAction,
  PersistedMaterialsBanner
} from '../../components/PersistedMaterialsDialog/PersistedMaterialsBanner'
import {PersistedMaterialsDialog} from '../../components/PersistedMaterialsDialog/PersistedMaterialsDialog'
import {ShowOrderSummarySection} from '../../components/ShowOrderSummaryBtn'
import {SummaryNoValidMaterials} from '../../components/SummaryNoValidMaterials'
import {isTwelveHoursFormat} from '../../components/TimeScroller/TimeScroller.utils'
import {DEFAULT_CUSTOMER_REFERENCE} from '../../declarations/constants'
import {ShippingType} from '../../declarations/OrderIntake.enums'
import {
  ActiveMaterialDeliveryItem,
  AddingNewMaterialMetadata,
  OrderIntakeMaterialOptionPayload,
  OrderRequest,
  OrderRequestGroupErrorResponse,
  OrderRequestGroupResponse,
  OrderRequestGroupSuccessResponse,
  PersistedOrderRequest,
  QuantityType
} from '../../declarations/types'
import {useBulkOrderFormChangeHandlerWithMethods} from '../../FormChangeHandlers'
import {useOrderIntakeData} from '../../hooks'
import {useActivePopup} from '../../hooks/useActivePopup'
import * as options from '../../Options'
import {isInvalidOrderIntakeOption} from '../../Options/query'
import {ContactSection} from '../containers/ContactSection'
import {MaterialOrderEditor} from '../containers/MaterialOrderEditor'
import {OrderSummarySection} from '../containers/OrderSummarySection'
import {useHandleClose} from '../hooks/useHandleClose'
import {useMaterialEditorController} from '../hooks/useMaterialEditorController'
import {
  areAllCustomerReferencesSame,
  getMaterialOptionsByInvalidity,
  isMaterialInvalidBySlots,
  mergePersistedContact,
  mergePersistedOrders,
  getValidMaterialOptions
} from '../utils'

import {DeliverMaterialForm} from './DeliverMaterialForm'
import {DeliveryOrderIntakeRequestConfirmation} from './DeliveryOrderIntakeRequestConfirmation'
import {MaterialSectionHeader, MaterialsHeader} from '../../components/VisualComponents'
import {useConfirmation} from '../../components/ConfirmationDialog/ConfirmationService'
import {usePostOrderRequestDelivery} from '../../../common/react-query/hooks/mutations/usePostOrderRequestDelivery'

export const BulkCementDeliverOrderForm = () => {
  const classes = useOrderIntakeStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {
    customerId,
    userId,
    userPrimaryOrganisationalUnit,
    userFullName,
    userProfileCountry,
    features: {
      orderIntakeCustomerReferenceField,
      hasSiteContactNameAndPhone,
      isMaterialPersistency,
      isContactPersistency
    }
  } = useOrderIntakeData()

  const openEditForm = useRef(true)
  const {getFeature} = useFeaturesState()
  const {notify} = useNotification()
  const confirm = useConfirmation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const isSlotsManagementEnabled = getFeature('OrderIntakeSlotsDeliver')
  const useConfigurableSlots = getFeature('OrderIntakeUseConfigurableSlots')
  const applyMaterialEnteredDescription = getFeature('OrderIntakeMaterialEnteredDescription')
  const applyContractItemDescription = getFeature('OrderIntakeContractItemDescription')

  const configuration = {
    isSlotsManagementEnabled,
    useConfigurableSlots,
    nextDayCollect: false,
    applyMaterialEnteredDescription,
    applyContractItemDescription
  }

  const {setDeliveries, slotConfiguration, setOrderSummary} = useBulkCementOrderIntake()
  const {isLoading, isError, mutateAsync} = usePostOrderRequestDelivery()

  const {data: deliverOptions} = options.useDeliveryOptions(configuration, customerId)
  const defaultBusinessLine = options.getBusinessLine(deliverOptions)

  const siteOptions = values(deliverOptions)
  const methods = useForm<BulkOrderFormValues>({
    mode: 'all',
    defaultValues: {
      selectedSite: siteOptions[0],
      orders: [],
      driverInstructions: '',
      isConfirmationMailChecked: true,
      contact: {
        mainPhone: undefined,
        fullName: undefined,
        backupPhone: undefined,
        carbonCopyEmail: undefined
      },
      isOrderRequestConfirmed: false,
      isBackupPhoneVisible: false
    }
  })

  const {
    fields: orderFields,
    append: appendOrder,
    remove: deleteOrder,
    replace: replaceOrders
  } = useFieldArray({
    control: methods.control,
    name: 'orders'
  })

  const {selectedSite, orders} = methods.watch()

  const siteNumber = methods.watch('selectedSite.shippingAddress.siteNumber')
  const {data: persistedContact} = usePersistedContact(siteNumber)
  const {data: persistedOrderRequests, isFetching: isOrderRequestsCacheLoading} =
    usePersistedOrderRequests(siteNumber)
  const {data: defaultDelivery} = options.useDefaultDelivery(customerId, configuration, siteNumber)

  useEffect(() => {
    setOrderSessionEventId(generateGuid())
  }, [])

  const formChangeHandlers = useBulkOrderFormChangeHandlerWithMethods(methods)

  useEffect(() => {
    if (
      !deliverOptions ||
      !userFullName ||
      !defaultDelivery ||
      isInvalidOrderIntakeOption(defaultDelivery)
    )
      return

    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)
    const orderRequests = orderRequestBySiteNumber ? [orderRequestBySiteNumber] : [defaultDelivery]
    const contact = mergePersistedContact(selectedSite, persistedContact, isContactPersistency)

    methods.setValue(
      'isConfirmationMailChecked',
      !first(persistedOrderRequests)?.isSendingConfirmationEmailUnChecked || true
    )

    formChangeHandlers.onSiteChange(siteNumber, deliverOptions, defaultDelivery)

    replaceOrders(
      orderRequests.map((orderRequest) => ({
        ...orderRequest,
        payload: {
          ...orderRequest.payload,
          customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
        },
        initialFullName: userFullName
      }))
    )

    methods.reset({
      ...methods.getValues(),
      isOrderRequestConfirmed: false,
      contact: {
        mainPhone: contact.mainPhone
          ? (parsePhoneNumberFromString(contact.mainPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        backupPhone: contact.backupPhone
          ? (parsePhoneNumberFromString(contact.backupPhone, userProfileCountry as CountryCode)
              ?.number as string)
          : '',
        fullName: contact.fullName ? contact.fullName : userFullName,
        carbonCopyEmail: contact.carbonCopyEmail,
        siteContactName: hasSiteContactNameAndPhone ? contact.siteContactName : ''
      }
    })

    getValidMaterialsForPersistency()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteNumber,
    persistedContact,
    persistedOrderRequests,
    userFullName,
    defaultDelivery,
    deliverOptions
  ])

  const [showConfirmationStatusScreen, setShowConfirmationStatusScreen] = useState<boolean>(false)
  const [editorActivationTimeStamp, setEditorActivationTimeStamp] = useState<string>()
  const [orderSessionEventId, setOrderSessionEventId] = useState<string>()
  const [showPersistedMaterialsBanner, setShowPersistedMaterialsBanner] = useState<boolean>(true)
  const [showPersistedMaterialsDialog, setShowPersistedMaterialsDialog] = useState<boolean>(false)
  const [validMaterialsForPersistency, setValidMaterialsForPersistency] = useState<
    OrderIntakeMaterialOptionPayload[]
  >([])
  const [existsValidPersistedMaterials, setExistsValidPersistedMaterials] = useState<boolean>(false)
  const [isAddingNewMaterial, setIsAddingNewMaterial] = useState<AddingNewMaterialMetadata>({
    isAddingNewMaterial: false,
    newMaterialIndex: -1
  })
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState<number>(-1)
  const [placeOrderStatus, setPlaceOrderStatus] = useState<boolean>(false)
  const [orderSummaryDataArray, setOrderSummaryDataArray] = useState<OrderRequestGroupResponse[]>(
    []
  )

  useEffect(() => {
    checkValidPersistedMaterials()
  }, [validMaterialsForPersistency, persistedOrderRequests])

  React.useEffect(() => {
    if (
      isAddingNewMaterial.isAddingNewMaterial &&
      orderFields.length === isAddingNewMaterial.newMaterialIndex + 1
    ) {
      const index = orderFields.length - 1
      handleEditorActivation(orderFields[index].id, 'details', index)
      setIsAddingNewMaterial({isAddingNewMaterial: false, newMaterialIndex: 0})
      return
    }

    if (openEditForm.current && orderFields?.length > 0) {
      openEditForm.current = false
      editFirstMaterial()
    }
  }, [isAddingNewMaterial, orderFields])

  React.useEffect(() => {
    setSelectedMaterialIndex(0)
    materialEditorController.setChanges(false)
    openEditForm.current = true
  }, [selectedSite])

  const editFirstMaterial = () => {
    const index = 0
    handleEditorActivation(orderFields[index].id, 'details', index)
  }

  const getValidMaterialsForPersistency = () => {
    const validMaterialOptions = getValidMaterialOptions(
      selectedSite,
      isSlotsManagementEnabled,
      orders,
      defaultDelivery as OrderRequest,
      slotConfiguration,
      isMaterialInvalidBySlots,
      useConfigurableSlots
    )

    setValidMaterialsForPersistency(validMaterialOptions)
  }

  const checkValidPersistedMaterials = () => {
    let existsValidMaterial = false
    persistedOrderRequests?.forEach((material) => {
      if (isMaterialValid(material)) existsValidMaterial = true
    })

    setExistsValidPersistedMaterials(existsValidMaterial)
  }

  const isMaterialValid = (material: PersistedOrderRequest) => {
    return validMaterialsForPersistency.some(
      (item) => item.material.materialEnteredNumber === material.materialEnteredNumber
    )
  }

  const handleClose = useHandleClose(
    orders,
    setShowConfirmationStatusScreen,
    methods.reset,
    customerId
  )

  const materialEditorController = useMaterialEditorController()

  const [activePopup, setActivePopup] = useActivePopup(
    materialEditorController.setActiveOrderBox,
    materialEditorController.activeMaterialForm
  )
  if (!deliverOptions || !selectedSite || !defaultDelivery) return null

  const addDelivery = () => {
    const currentOrdersCount = orders.length
    const lastOrderItem = orders[orders.length - 1]
    appendOrder({...lastOrderItem, payload: {...lastOrderItem.payload, haulerInfo: {}}})
    trackEvent('hubOrderIntakeAddOrderRequest')
    setIsAddingNewMaterial({isAddingNewMaterial: true, newMaterialIndex: currentOrdersCount})
    methods.setValue('isOrderRequestConfirmed', true)
  }

  const removeDelivery = (index: number) => {
    deleteOrder(index)
    trackEvent('hubOrderIntakeRemoveOrderRequest')

    materialEditorController.setActiveOrderBox(undefined)
  }

  const quantityType =
    (defaultDelivery as OrderRequest)?.payload?.capacity?.quantityType ?? QuantityType.TruckCapacity
  const quantityUom = first(
    selectedSite.materials[(defaultDelivery as OrderRequest)?.payload?.materialEnteredNumber]
  )?.truckCapacity.capacityUom

  const isConfigurableSlots = getFeature('OrderIntakeUseConfigurableSlots')
  const isAutoConfirmed = getFeature('OrderIntakeSlotsDeliverAutoConfirmedOrderingWhenFree')

  const onSubmit: SubmitHandler<BulkOrderFormValues> = (data) => {
    replaceOrders(
      orders.map((delivery) => {
        const defaultMaterialOption =
          selectedSite.materials[delivery.payload.materialEnteredNumber][0]

        const businessDays = defaultMaterialOption.dateRange?.businessDays
          ? defaultMaterialOption.dateRange?.businessDays
          : []
        const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
        const cutOffTimeStamp = todaysCutOffTime?.timestamp

        if (
          isCurrentTimeAfterCutOff(
            cutOffTimeStamp,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0]?.businessHours
              ?.timeZone
          ) &&
          !isDateAvailable(
            delivery.payload.deliveryDate,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0].dateRange,
            selectedSite.materials[delivery.payload.materialEnteredNumber][0].businessHours.timeZone
          )
        ) {
          return {
            ...delivery,
            isDateNotAvailable: true
          }
        }
        return {
          ...delivery,
          isDateNotAvailable: false
        }
      })
    )

    if (some(orders, 'isDateNotAvailable')) return

    const updatedDeliveries = data.orders.map<OrderRequest>((delivery) => ({
      ...delivery,
      payload: {
        ...delivery.payload,
        additionalDriverInfo: {
          ...delivery.payload.additionalDriverInfo
        },
        isSendingConfirmationEmailUnChecked: !data.isConfirmationMailChecked,
        contact: {
          ...data.contact,
          backupPhone:
            isEmpty(data.contact?.backupPhone) && hasSiteContactNameAndPhone
              ? data.contact?.mainPhone
              : data.contact?.backupPhone
        }
      }
    }))

    methods.setValue('isOrderRequestConfirmed', false)

    setDeliveries(updatedDeliveries)
    setPlaceOrderStatus(true)

    Promise.all(
      updatedDeliveries.map((delivery, index) => {
        return mutateAsync({
          orderIntakePayload: delivery.payload,
          isConfigurableSlots,
          isAutoConfirmed
        })
          .then((data) => {
            return {
              index,
              result: data,
              isSuccess: true,
              delivery
            } as OrderRequestGroupSuccessResponse
          })
          .catch((error) => {
            return {
              index,
              result: error,
              isSuccess: false,
              delivery
            } as OrderRequestGroupErrorResponse
          })
      })
    )
      .then((dataArray) => {
        setOrderSummaryDataArray(dataArray)

        const successfulResults = dataArray.filter(
          (data) => data.isSuccess
        ) as OrderRequestGroupSuccessResponse[]

        successfulResults.forEach((data) => {
          setOrderSummary(data.result)
        })

        setPlaceOrderStatus(false)
        setShowConfirmationStatusScreen(true)
      })
      .catch((error) => {
        console.error('Error for delivery Unexpected error:', error)
      })

    if (!isEmpty(data.driverInstructions)) {
      trackEvent('hubOrderIntakeInstructionAdded')
      const isCustomerInstructionsAddedRegex = new RegExp(/(\w|\s|\W)+Customer: (\w|\s|\W)+/g)
      if (isCustomerInstructionsAddedRegex.test(data.driverInstructions ?? '')) {
        trackEvent('hubOrderIntakeAdditionalDriverInstructionsAdded')
      }
    }

    updatedDeliveries.forEach((delivery) => {
      if (delivery.payload.deliveryDate !== delivery.initialDeliveryDate) {
        trackEvent('hubOrderIntakeDefaultDateNotUsed')
      }
      if (!isEqual(delivery.payload.deliveryTime, delivery.initialDeliveryTime)) {
        trackEvent('hubOrderIntakeDefaultTimeNotUsed')
      }
      if (delivery.payload.capacity.quantity !== delivery.initialTruckQuantity) {
        trackEvent('hubOrderIntakeDefaultQuantityNotUsed')
      }
      if (!isEqual(delivery.payload.contact?.fullName, delivery.initialFullName)) {
        trackEvent('hubOrderIntakeOrderPlacerNameChanged')
      }
    })
    trackEvent('hubOrderIntakeEnd', {
      shippingType: ShippingType.DELIVER,
      numberOfMaterials: uniq(
        updatedDeliveries.map((delivery) => delivery.payload.materialEnteredNumber)
      ).length,
      businessLine: defaultBusinessLine,
      quantityType,
      orderVolume: updatedDeliveries.reduce((result, delivery) => {
        return {
          ...result,
          [delivery.payload.materialEnteredNumber]:
            (result[delivery.payload.materialEnteredNumber] || 0) +
            delivery.payload.capacity.quantity
        }
      }, {}),
      numberOfOrderRequests: updatedDeliveries.length,
      purchaseOrderNumberVisible: orderIntakeCustomerReferenceField
    })
    if (
      updatedDeliveries[0].payload.contact?.mainPhone !==
      updatedDeliveries[0].payload.contact?.backupPhone
    )
      trackEvent('hubOrderIntakeAlternativePhoneNumberUsed')

    const isTwelveHours = isTwelveHoursFormat(language)

    orders.map((delivery) => {
      if (delivery.timeTrackingData) {
        delivery.timeTrackingData.map((userInteraction) => {
          trackEvent('hubOrderIntakeEditTime', {
            orderSessionEventId,
            cardId: userInteraction.cardId,
            slotManaged: userInteraction.slotManaged,
            startEditingTimeStamp: userInteraction.startEditingTimeStamp,
            applyChangesTimeStamp: userInteraction.applyChangesTimeStamp,
            events: userInteraction.events,
            earliestTimeFormat: isTwelveHours ? '12h' : '24h',
            latestTimeFormat: isTwelveHours ? '12h' : '24h'
          })
        })
      }
    })
  }

  const onCustomerReferenceChange = (index: number, customerReference: string) => {
    methods.setValue(`orders.${index}.payload.customerReference`, customerReference)
  }

  const onQuantityChange = (index: number, quantity: number) => {
    methods.setValue(`orders.${index}.payload.capacity.quantity`, quantity)
    methods.setValue('isOrderRequestConfirmed', true)
  }

  const onDriverInstructionsChange = (index: number, driverInstructions: string) => {
    methods.setValue(
      `orders.${index}.payload.additionalDriverInfo.driverInstructions`,
      driverInstructions
    )
  }

  const onMaterialChange = (
    index: number,
    selectedMaterialOption: OrderIntakeMaterialOptionPayload
  ) => {
    formChangeHandlers.onMaterialChange(index, selectedMaterialOption)
  }

  const handleEditorActivation = (id: string, item: ActiveMaterialDeliveryItem, index: number) => {
    if (materialEditorController.hasActiveMaterialFormChanges) {
      void confirm({
        'data-test-id': 'order-intake-confirmation-dialog',
        title: t('orderIntake.materialOrder.confirmDialog.title'),
        submitButtonTitle: t('orderIntake.materialOrder.confirmDialog.submitButton'),
        cancelButtonTitle: t('orderIntake.materialOrder.confirmDialog.cancelButton'),
        variant: 'discard'
      }).then(() => {
        materialEditorController.setActiveOrderBox(id, item, 'add')
        onEditorActivation(index)
      })
    } else {
      materialEditorController.setActiveOrderBox(id, item, 'add')
      onEditorActivation(index)
    }
  }

  const handleStatusChange = (hasChanges: boolean) => {
    materialEditorController.setChanges(hasChanges)
  }

  const onEditorActivation = (index: number) => {
    const orderPath = `orders.${index}` as const
    const order = methods.getValues(orderPath)

    const defaultMaterialOption = first(selectedSite.materials[order.payload.materialEnteredNumber])
    const timeZone = defaultMaterialOption?.businessHours.timeZone
    setEditorActivationTimeStamp(getCurrentTimeStamp(checkEmptyTimeZone(timeZone)))

    methods.setValue('isOrderRequestConfirmed', true)
    setSelectedMaterialIndex(index)
  }

  const onClosePersistedMaterialsBanner = (action: PersistedMaterialsAction) => {
    switch (action) {
      case PersistedMaterialsAction.ApplyMaterials: {
        applyPersistedMaterials()
        trackPersistedMaterialsShortcutButton()
        notify('info', t('orderIntake.materialPersistency.confirmationMessage'), {
          autoHideDuration: 2000
        })
        return
      }
      case PersistedMaterialsAction.ShowMaterials: {
        setShowPersistedMaterialsDialog(true)
        return
      }
      default: {
        setShowPersistedMaterialsBanner(false)
      }
    }
  }

  const onClosePersistedMaterials = (apply: boolean) => {
    setShowPersistedMaterialsDialog(false)

    if (apply) {
      applyPersistedMaterials()
    }
  }

  const applyPersistedMaterials = () => {
    openEditForm.current = true
    const orderRequestBySiteNumber = orders.find((or) => or.payload.siteNumber === siteNumber)

    const validPersistedOrderRequests = persistedOrderRequests?.filter((persistedItem) =>
      validMaterialsForPersistency.some(
        (item) => item.material.materialEnteredNumber === persistedItem.materialEnteredNumber
      )
    )

    const orderRequests = mergePersistedOrders(
      orderRequestBySiteNumber ? orderRequestBySiteNumber : (defaultDelivery as OrderRequest),
      selectedSite,
      validPersistedOrderRequests
    )

    if (orderRequests) {
      replaceOrders(
        orderRequests.map((orderRequest) => ({
          ...orderRequest,
          payload: {
            ...orderRequest.payload,
            customerReference: orderRequest.payload.customerReference || DEFAULT_CUSTOMER_REFERENCE
          }
        }))
      )
    }

    trackEvent('hubOrderIntakeReviewPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.DELIVER,
      businessLine: defaultBusinessLine
    })
  }

  const trackPersistedMaterialsShortcutButton = () => {
    trackEvent('hubOrderIntakeShortcutCopyPersistedMaterials', {
      user_uuid: userId,
      country: userProfileCountry,
      primaryOrganisationalUnit: userPrimaryOrganisationalUnit,
      customerId: customerId,
      siteId: siteNumber,
      shippingType: ShippingType.DELIVER,
      businessLine: defaultBusinessLine
    })
  }

  const handleSummarySectionEditButtonClick = (index: number) => {
    handleEditorActivation(orderFields[index].id, 'details', index)
  }

  const isCustomerReferenceSame = areAllCustomerReferencesSame(orders)
  const validMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, false)
  const invalidMaterialOptions = getMaterialOptionsByInvalidity(selectedSite.materials, true)
  const noValidMaterial =
    (invalidMaterialOptions.length >= 1 && isEmpty(validMaterialOptions)) ||
    isInvalidOrderIntakeOption(defaultDelivery)

  // we cannot have form in form, if so, it won't work and submit button will trigger upper form
  // so when there is activeMaterialForm, we disable the form and render only div
  const isMainFormActive = !materialEditorController.activeMaterialForm
  const Form = !materialEditorController.activeMaterialForm ? 'form' : 'div'
  return (
    <FormProvider {...methods}>
      <Form onSubmit={isMainFormActive ? methods.handleSubmit(onSubmit) : undefined}>
        {showConfirmationStatusScreen && (
          <DeliveryOrderIntakeRequestConfirmation
            businessLine={defaultBusinessLine}
            quantityType={quantityType}
            quantityUom={quantityUom ?? ''}
            onClose={handleClose}
            groupOrderSummaryDataArray={orderSummaryDataArray ?? []}
            isLoading={isLoading}
            isError={isError}
          />
        )}

        {!showConfirmationStatusScreen && (
          <Grid container style={{padding: 0}}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                {isMaterialPersistency &&
                  persistedOrderRequests &&
                  showPersistedMaterialsDialog && (
                    <Dialog open={true} onClose={onClosePersistedMaterials}>
                      <DialogContent>
                        <PersistedMaterialsDialog
                          persistedMaterials={persistedOrderRequests}
                          validMaterials={validMaterialsForPersistency}
                          existsValidMaterials={existsValidPersistedMaterials}
                          onClose={onClosePersistedMaterials}
                        />
                      </DialogContent>
                    </Dialog>
                  )}

                <Grid
                  container
                  className={classes.container}
                  justifyContent="space-between"
                  data-test-id="order-intake-form-container"
                  style={{padding: 0}}
                >
                  <Grid container spacing={4}>
                    <Hidden mdDown={!!activePopup}>
                      <Grid item xs={12} md={8} style={{paddingRight: 0}}>
                        <Paper
                          style={
                            isMobile ? {borderRadius: 0, background: '#F5F8FB', padding: 16} : {}
                          }
                          className={classes.paperStyle}
                        >
                          <MaterialSectionHeader data-test-id="delivery-site-selection-section-header">
                            {t('orderIntake.deliveryAddress')}
                          </MaterialSectionHeader>
                          <Grid item xs={12} sm={8}>
                            <Controller
                              control={methods.control}
                              name="selectedSite"
                              render={({field}) => (
                                <SiteDropdown
                                  onChange={partialRight(
                                    formChangeHandlers.onSiteChange,
                                    deliverOptions,
                                    defaultDelivery
                                  )}
                                  selectedSite={field.value}
                                  shippingType={ShippingType.DELIVER}
                                  options={siteOptions}
                                  fetchOrderIntakeOptions={() =>
                                    invalidateHubQuery('orderRequestsOptions', {
                                      customerId,
                                      applyMaterialEnteredDescription,
                                      applyContractItemDescription
                                    })
                                  }
                                />
                              )}
                            />
                          </Grid>

                          {isMaterialPersistency &&
                            persistedOrderRequests &&
                            showPersistedMaterialsBanner &&
                            !noValidMaterial && (
                              <PersistedMaterialsBanner
                                onClose={onClosePersistedMaterialsBanner}
                                existsValidMaterials={existsValidPersistedMaterials}
                              />
                            )}

                          {!noValidMaterial ? (
                            isOrderRequestsCacheLoading ? (
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                marginTop={10}
                              >
                                <img
                                  src={distributedCacheLoadingImage}
                                  data-test-id="order-intake-loading-data-from-cache"
                                  style={{textAlign: 'center', maxWidth: 350}}
                                  alt={t('orderIntake.loadingDataFromCache')}
                                />
                                <Box marginTop={2}>
                                  <Typography
                                    variant="body2"
                                    color="secondary"
                                    style={{textAlign: 'center', maxWidth: 350}}
                                  >
                                    {t('orderIntake.loadingDataFromCache')}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <>
                                <Stack marginTop={4}>
                                  <MaterialSectionHeader
                                    style={{marginTop: 20}}
                                    data-test-id="delivery-material-selection-section-header"
                                  >
                                    {t('orderIntake.cementTypeDelivery')}
                                  </MaterialSectionHeader>
                                  <TableContainer style={{marginBottom: 20}}>
                                    <Table>
                                      <Hidden smDown>
                                        <MaterialsHeader
                                          showHaulierInformation={false}
                                          shippingType={ShippingType.DELIVER}
                                        />
                                      </Hidden>
                                      <TableBody>
                                        {orderFields.map((delivery, index) => {
                                          const defaultMaterialOption = getDefaultMaterialOptions(
                                            selectedSite.materials,
                                            delivery.payload
                                          )
                                          const {status, isSuccess} =
                                            materialEditorController.getMaterialFormProps(
                                              delivery.id
                                            )
                                          if (!defaultMaterialOption) return null

                                          return (
                                            <DeliverMaterialForm
                                              key={delivery.id}
                                              selectedSite={selectedSite}
                                              defaultMaterialOption={defaultMaterialOption}
                                              orderIndex={index}
                                              removeDelivery={() => removeDelivery(index)}
                                              isSuccess={isSuccess}
                                              status={status}
                                              checkValidityBySlots={
                                                isSlotsManagementEnabled && useConfigurableSlots
                                              }
                                              slotConfiguration={slotConfiguration}
                                              onActivate={(item) => {
                                                handleEditorActivation(delivery.id, item, index)
                                              }}
                                            />
                                          )
                                        })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  <AddAnotherButton
                                    businessLine={defaultBusinessLine}
                                    disabled={isOrderRequestsCacheLoading}
                                    onClick={addDelivery}
                                  />
                                </Stack>
                                <Box marginTop={2}>
                                  <ContactSection
                                    shippingType={ShippingType.DELIVER}
                                    dataTestPrefix="delivery"
                                  />
                                </Box>
                              </>
                            )
                          ) : null}
                          {noValidMaterial ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginTop={10}
                            >
                              <img
                                src={noValidMaterialImage}
                                data-test-id="order-intake-no-valid-ship-to-image"
                                style={{textAlign: 'center', maxWidth: 350}}
                                alt={t(
                                  'orderIntake.invalidMaterials.deliveryNoPossibleForDestination'
                                )}
                              />
                              <Box marginTop={2}>
                                <Typography
                                  variant="body2"
                                  color="secondary"
                                  style={{textAlign: 'center', maxWidth: 350}}
                                >
                                  {t(
                                    'orderIntake.invalidMaterials.deliveryNoPossibleForDestinationMessage'
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          ) : null}
                        </Paper>
                      </Grid>
                    </Hidden>
                    <Hidden smDown={!activePopup}>
                      <Grid item xs={12} md={4} style={{paddingLeft: 16, paddingRight: 0}}>
                        <Paper className={classes.paperStyle}>
                          <div className={classes.stickyContainer}>
                            {placeOrderStatus ? (
                              <Box display="flex" alignItems="center" flexDirection="column">
                                <CircularProgress size={24} color="inherit" />
                              </Box>
                            ) : materialEditorController.activeMaterialForm === undefined ||
                              noValidMaterial ? (
                              <OrderSummarySection
                                shippingType={ShippingType.DELIVER}
                                customerId={customerId}
                                quantityType={quantityType}
                                onCloseSummaryBox={() => setActivePopup(undefined)}
                                onCustomerRefChange={formChangeHandlers.onGlobalCustomerRefChange}
                                onEmailCcChange={formChangeHandlers.onGlobalEmailCcChange}
                                onEditItem={handleSummarySectionEditButtonClick}
                              />
                            ) : (
                              orderFields.length > 0 &&
                              selectedMaterialIndex > -1 &&
                              selectedMaterialIndex < orderFields.length && (
                                <MaterialOrderEditor
                                  key={materialEditorController.activeMaterialForm}
                                  orderPayload={orderFields[selectedMaterialIndex].payload}
                                  canDeleteMaterial={orders.length > 1}
                                  isCustomerReferenceSame={isCustomerReferenceSame}
                                  checkValidityBySlots={
                                    isSlotsManagementEnabled && useConfigurableSlots
                                  }
                                  index={selectedMaterialIndex}
                                  shippingType={ShippingType.DELIVER}
                                  activeItem={materialEditorController.activeSection}
                                  editorMode={materialEditorController.activeMode}
                                  showPoTab={!isCustomerReferenceSame}
                                  closeActiveItem={() =>
                                    materialEditorController.setActiveOrderBox(undefined)
                                  }
                                  onChange={() =>
                                    materialEditorController.activeMaterialForm &&
                                    materialEditorController.notifySuccessfullySaved(
                                      materialEditorController.activeMaterialForm
                                    )
                                  }
                                  onDateChange={partial(
                                    formChangeHandlers.onDateChange,
                                    selectedMaterialIndex
                                  )}
                                  onTimeChange={partial(
                                    formChangeHandlers.onTimeChange,
                                    selectedMaterialIndex
                                  )}
                                  onCustomerReferenceChange={(customerReference) =>
                                    onCustomerReferenceChange(
                                      selectedMaterialIndex,
                                      customerReference
                                    )
                                  }
                                  onQuantityChange={(quantity) =>
                                    onQuantityChange(selectedMaterialIndex, quantity)
                                  }
                                  onDriverInstructionsChange={(driverInstructions) =>
                                    onDriverInstructionsChange(
                                      selectedMaterialIndex,
                                      driverInstructions
                                    )
                                  }
                                  onMaterialChange={(selectedMaterialOption) =>
                                    onMaterialChange(selectedMaterialIndex, selectedMaterialOption)
                                  }
                                  onTimeSelectorTrack={partial(
                                    formChangeHandlers.onTimeSelectorTrackApply,
                                    selectedMaterialIndex,
                                    editorActivationTimeStamp,
                                    isSlotsManagementEnabled,
                                    orderFields[selectedMaterialIndex].id
                                  )}
                                  onDeleteMaterial={() => removeDelivery(selectedMaterialIndex)}
                                  onStatusChange={handleStatusChange}
                                />
                              )
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smUp smDown={!!activePopup}>
              {!noValidMaterial ? (
                <ShowOrderSummarySection
                  isMobile={isMobile}
                  onOpenOrderSummary={() => setActivePopup('OrderSummary')}
                  disabled={
                    !methods.formState.isValid || noValidMaterial || isOrderRequestsCacheLoading
                  }
                />
              ) : (
                <Grid item xs={12}>
                  <Card>
                    <SummaryNoValidMaterials invalidMaterialOptions={invalidMaterialOptions} />
                  </Card>
                </Grid>
              )}
            </Hidden>
          </Grid>
        )}
      </Form>
    </FormProvider>
  )
}
