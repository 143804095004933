import {get} from 'lodash'

import {Delivery} from '../../Pages/Finance/Invoices/Delivery.types'
import {concatIds} from '../../util/reducer.helper'

import {
  DELIVERIES_ORDERS_FETCH_FAILURE,
  DELIVERIES_ORDERS_FETCH_REQUEST,
  DELIVERIES_ORDERS_FETCH_SUCCESS,
  NormalizedOrderStoreType,
  ORDERS_FETCH_SUCCESS,
  OrderDeliveriesActionTypes,
  OrderDeliveriesStateType,
  OrdersActionTypes
} from './Action.types'

export const initialDeliveriesState: OrderDeliveriesStateType = {
  loading: false,
  byId: {},
  allIds: []
}

export const deliveries = (
  state = initialDeliveriesState,
  action: OrderDeliveriesActionTypes | OrdersActionTypes
) => {
  switch (action.type) {
    case ORDERS_FETCH_SUCCESS: {
      // FIXME: THE CODE BELOW MOCKS A MISSING API RESPONSE
      // FIXME: REPLACE ONCE API responds with links, see https://jira.grouphc.net/browse/DF-1282

      const deliverybyId = {...state.byId}

      // let counter = 0
      for (const order of action.payload) {
        if (!order.deliveries) continue
        for (const delivery of get(order, 'deliveries', [])) {
          // if (counter % 2 === 0)
          //   delivery.links.push({
          //     href: 'documents/someHashCode',
          //     media: 'application/pdf',
          //     rel: 'documents',
          //     type: 'deliveryNote',
          //   })
          // counter += 1
          deliverybyId[delivery.deliveryId] = delivery
        }
      }
      return {
        ...state,
        byId: {...deliverybyId},
        allIds: concatIds(state.allIds, Object.keys(deliverybyId))
      }
    }

    case DELIVERIES_ORDERS_FETCH_REQUEST:
      return {
        ...state,
        loading: true
      }

    case DELIVERIES_ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        byId: {
          ...state.byId,
          ...action.payload.reduce(
            (prev: NormalizedOrderStoreType, item: Delivery) => ({
              ...prev,
              [item.deliveryId]: item
            }),
            {}
          )
        },
        allIds: concatIds(
          state.allIds,
          action.payload.map((item: Delivery) => item.deliveryId)
        )
      }

    case DELIVERIES_ORDERS_FETCH_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    default:
      return state
  }
}
