import {darken, Divider as DividerMui, DividerProps as DividerPropsMui} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'
import {DividerProps} from './types'

const onBlue = 'rgba(255, 255, 255, 0.5)'
const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: '0 !important'
  },
  strong: {
    backgroundColor: '#1a4b5f'
  },
  light: {
    backgroundColor: theme.palette.text.primarySoft
  },
  soft: {
    backgroundColor: theme.palette.divider
  },
  dark: {
    backgroundColor: '#979797'
  },
  onBlue: {
    backgroundColor: onBlue
  },
  onBlueDisabled: {
    backgroundColor: darken(onBlue ?? onBlue, 0.5)
  },
  primary: {
    backgroundColor: theme.palette.secondary.dark
  },
  onLight: {
    backgroundColor: '#e7e7e7'
  },
  onLightDisabled: {
    backgroundColor: '#b7b7b7'
  }
}))

const Divider: React.FC<DividerProps & DividerPropsMui> = ({
  color = 'strong',
  className: classNameProp,
  ...props
}) => {
  const {classes} = useStyles()

  return (
    <DividerMui
      className={` ${classes[color]} ${classNameProp ?? ''} ${classes.divider}`}
      {...props}
    />
  )
}

export default Divider
