import {ErrorPaper, Typography} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {Product} from '../../Pages/Certificate/Cement/types'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'

const useStyles = makeStyles()((theme) => ({
  selectDropdown: {
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      width: '100%',

      '& button': {
        width: '100%',
        maxWidth: 'unset'
      }
    }
  }
}))

interface SelectItemProps {
  materialNumber?: string
  onLight?: boolean
}
const SelectItem: React.FC<SelectItemProps> = ({materialNumber, onLight}) => (
  <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
    {materialNumber}
  </Typography>
)

interface ProductsDropdownProps {
  error: any
  products?: Product[]
  selectedProductId?: string
  props?: any
  label?: string
  onChange?: any
  noSelectionLabel?: string
  onLight?: boolean
}
export const ProductsDropdown: React.FC<ProductsDropdownProps> = ({
  error,
  products,
  selectedProductId,
  onLight,
  onChange,
  ...props
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const selectedItem =
    products && products.find((product: Product) => product.materialNumber === selectedProductId)
  return (
    <div className={classes.selectDropdown}>
      <SelectDropdown
        label={t('certificate.productsDropdown.label')}
        noSelectionLabel={t('certificate.productsDropdown.noSelectionLabel')}
        showError={!!error}
        errorRender={() => <ErrorPaper variant="primaryDark" />}
        onChange={onChange}
        data-test-id="certificates-filter-product-dropdown"
        options={products}
        renderItem={(item: Product) => (
          <SelectItem materialNumber={item.materialNumber} onLight={onLight} />
        )}
        stringifyItem={(item: Product) => item.materialNumber}
        identifierKey="materialNumber"
        selectedItem={selectedItem}
        onLight={onLight}
        {...props}
      />
    </div>
  )
}
