import {Page, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {makeStyles, Paper} from '@material-ui/core'
import React from 'react'

import {InvitationsList} from './components/InvitationsList'
import Content from '../../../Molecules/Content'

interface OverviewPageProperties {
  loggedInUserId: string
}

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    padding: '12px 0'
  },
  pageContent: {
    [theme.breakpoints.down('md')]: {
      padding: '0 8px 8px 8px'
    }
  }
}))

export const InvitationsOverviewPage: React.FC<OverviewPageProperties> = ({loggedInUserId}) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const classes = useStyles()
  return (
    <Content className={classes.pageContent}>
      <Page
        data-test-id="overview-page"
        title={t('invitations.navigationLabel')}
        px={isMobile ? 0 : 2}
        py={isMobile ? 0 : 2}
        classNameHeader={classes.pageTitle}
      >
        <Paper elevation={4} style={{padding: `0px ${isMobile ? '16px' : '24px'}`}}>
          <InvitationsList loggedInUserId={loggedInUserId} />
        </Paper>
      </Page>
    </Content>
  )
}
