import {useShellContext} from '@hconnect/uikit'
import React, {ReactNode} from 'react'
import {Toolbar} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const LayoutConstants = {
  DEFAULT_CONTENT_WIDTH: 1280,
  MIN_CONTENT_WIDTH: 1024
}
const useStyles = makeStyles()((theme) => ({
  container: {
    padding: `0 ${theme.spacing(6)}`
  },
  boxed: {
    width: '100%',
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

interface SubHeaderProps {
  boxed?: boolean
  children?: ReactNode
}

const SubHeader: React.FC<SubHeaderProps> = ({children, boxed}) => {
  const {boxed: defaultBoxed} = useShellContext()
  const {classes} = useStyles()
  const useBoxedLayout = boxed !== undefined ? boxed : defaultBoxed
  return (
    <div className={classes.container}>
      <Toolbar className={`${useBoxedLayout ? classes.boxed : ''}`} disableGutters>
        {children}
      </Toolbar>
    </div>
  )
}

export default SubHeader
