import useDropdownStyle from './DropdownStyles'
import {TitleButton} from './TitleButton'
import {Box, CircularProgress, Popover} from '@mui/material'
import {LoadingStateProps} from './types'

export const LoadingState = ({
  popoverStyle,
  label,
  classNameProp,
  dark,
  long,
  anchorEl,
  onLight,
  t,
  open,
  setOpen,
  highlighted,
  inWidget,
  smallscreen
}: LoadingStateProps) => {
  const {classes} = useDropdownStyle()

  return (
    <div className={classes.root} aria-label={`${label} dropdown`}>
      <TitleButton
        className={`${classNameProp} ${
          dark ? `${classes.button} ${classes.dark}` : classes.button
        }`}
        long={long}
        dark={dark}
        buttonRef={anchorEl}
        onClick={() => setOpen(!open)}
        isLoading={true}
        selectedLabel={`${t('loading')}...`}
        highlighted={highlighted}
        inWidget={inWidget}
        smallScreen={smallscreen}
      />
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{paper: onLight ? classes.popoverOnLight : classes.popover}}
        style={popoverStyle ? popoverStyle : {left: -20}}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          padding={5}
        >
          <CircularProgress />
        </Box>
      </Popover>
    </div>
  )
}
