/* eslint-disable complexity */
import {Typography, BorderedTextField, ActionButton} from '@hconnect/uikit'
import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Controller, ControllerRenderProps, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AddCircle} from '@material-ui/icons'

import {Features} from '../../../../Organisms/Features'
import {isValidCustomerReference} from '../../../../Organisms/OrderIntake/utils'
import {ShippingType} from '../../../declarations/OrderIntake.enums'
import {
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderRequest,
  QuantityType,
  SlotConfiguration,
  TimeSelectorTrackingEventType
} from '../../../declarations/types'
import {MaterialOrderForm} from './types'
import {Box, Stack, Switch} from '@mui/material'
import {ShippingAddress} from '../../../components/ShippingAddress'
import {isEmpty} from 'lodash'
import {DropdownItem, MaterialDropdownItem} from '../../../components/SiteDropdown'
import {useOrderIntakeStyles} from '../../../../../src/Hooks/OrderIntake/useOrderIntakeStyles'
import {NumericInputWithControls} from '../../../components/Forms/NumericInputWithControls'
import {QuantityUomToSymbolComponent} from '../../../../../src/Pages/Order/Order.components'
import {FullWidthStack} from '../../../components/FullWidthStack'
import {Info} from '@mui/icons-material'
import {InfoTextWithIcon} from '../../../../../src/Molecules/InfoTextWithIcon'
import {trackInvalidMaterialsFactory} from '../../../trackEvents'
import {searchInContent, SelectDropdown} from '@hconnect/uikit/src/lib2'
import {MaterialSectionHeader} from '../../../components/VisualComponents'
import {usePersistedOrderRequests} from '../../../../../src/common/react-query/hooks/queries/usePersistedOrderRequests'

const DRIVER_INSTRUCTIONS_MAX_LENGTH = 200

const selectIdentity = (o?: OrderIntakeMaterialOptionPayload) => o?.material.materialDescription
const selectPlantName = (o?: OrderIntakeMaterialOptionPayload) => o?.plant.plantName

type Props = {
  index: number
  isTimeForOrderOver: boolean
  selectedSite: OrderIntakeOption
  slotConfigurations?: SlotConfiguration[]
  validMaterialOptions: OrderIntakeMaterialOptionPayload[]
  invalidMaterialOptions: OrderIntakeMaterialOptionPayload[]
  orderRequest: OrderRequest
  defaultMaterialOption: OrderIntakeMaterialOptionPayload
  shippingType: ShippingType
  onTimeSelectorTrack?: (eventType: TimeSelectorTrackingEventType) => void
  defaultDriverInstructions: string
  setDefaultDriverInstructions: (instructions: string) => void
  activeSelectedCustomerReference: string
  setActiveSelectedCustomerReference: (customerReference: string) => void
}

export const DetailsTab: React.FC<Props> = ({
  isTimeForOrderOver,
  selectedSite,
  validMaterialOptions,
  invalidMaterialOptions,
  defaultMaterialOption,
  shippingType,
  defaultDriverInstructions,
  setDefaultDriverInstructions,
  activeSelectedCustomerReference,
  setActiveSelectedCustomerReference
}) => {
  const {t} = useTranslation()
  const classes = useOrderIntakeStyles()
  const methods = useFormContext<MaterialOrderForm>()

  const [readOnlyDriversInstructions] = useState<string | undefined>(
    selectedSite?.driverInstructions ?? ''
  )
  const isReadOnlyDriversInstructionsAvailable = readOnlyDriversInstructions
    ? readOnlyDriversInstructions.length > 0
    : false

  const [showDriverInstructionsInput, setShowDriverInstructionsInput] = useState<boolean>(
    !isReadOnlyDriversInstructionsAvailable
  )

  const [enabledDriverInstructions, setEnabledDriverInstructions] = useState<boolean>(false)
  const [driverInstructionsInputInvalid, setDriverInstructionsInputInvalid] =
    useState<boolean>(false)

  const [resolvedDefaultDriverInstructions, setResolvedDefaultDriverInstructions] =
    useState<string>('')

  const customerPrefix = ' Customer: '
  const additionalInstructionsMaxLength = readOnlyDriversInstructions
    ? DRIVER_INSTRUCTIONS_MAX_LENGTH - readOnlyDriversInstructions.length - customerPrefix.length
    : DRIVER_INSTRUCTIONS_MAX_LENGTH - customerPrefix.length

  const {isFetching: isOrderRequestsCacheLoading} = usePersistedOrderRequests(
    selectedSite.shippingAddress.siteNumber
  )

  useEffect(() => {
    const readOnlyDriversInstructions = selectedSite?.driverInstructions ?? ''
    const isReadOnlyDriversInstructionsAvailable = readOnlyDriversInstructions
      ? readOnlyDriversInstructions.length > 0
      : false

    let resolvedInstructions = defaultDriverInstructions.replace(
      `${readOnlyDriversInstructions}${customerPrefix}`,
      ''
    )
    if (
      isReadOnlyDriversInstructionsAvailable &&
      !defaultDriverInstructions.includes(customerPrefix)
    ) {
      resolvedInstructions = ''
    }
    setResolvedDefaultDriverInstructions(resolvedInstructions)

    if (isReadOnlyDriversInstructionsAvailable) {
      setShowDriverInstructionsInput(defaultDriverInstructions ? true : false)
    } else {
      setShowDriverInstructionsInput(true)
    }

    setEnabledDriverInstructions(defaultDriverInstructions ? true : false)
  }, [])

  useEffect(() => {
    methods.setValue('details.driverInstructions', resolvedDefaultDriverInstructions)
  }, [resolvedDefaultDriverInstructions])

  useEffect(() => {
    methods.setValue('details.customerReference', activeSelectedCustomerReference)
  }, [activeSelectedCustomerReference])

  const handleEnabledDriverInstructions = (checked: boolean) => {
    setEnabledDriverInstructions(checked)
    methods.setValue('details.driverInstructions', resolvedDefaultDriverInstructions)
    if (!checked) {
      methods.setValue('details.driverInstructions', '', {shouldDirty: true})
      methods.clearErrors('details')
      setDriverInstructionsInputInvalid(false)
    }
  }

  const onDriverInstructionsChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    const validInputPattern = /["\nçÇ#!.]/g
    const found = inputValue.match(validInputPattern)

    setDefaultDriverInstructions(inputValue)

    if (found && found.length > 0) {
      setDriverInstructionsInputInvalid(true)
      methods.setError('details', {type: 'custom'})
    } else {
      setDriverInstructionsInputInvalid(false)
      methods.clearErrors('details')
    }
  }

  const capacity = defaultMaterialOption.truckCapacity
  const {minCapacity, maxCapacity, capacityUom} = capacity
  const unitOfMeasure =
    defaultMaterialOption.material.quantityType !== QuantityType.Load ? capacityUom : 'Loads'

  const decTooltipTitle = t('orderIntake.minTruckCapacity', {
    capacity: minCapacity,
    capacityUom: unitOfMeasure
  })

  const trackInvalidMaterials = trackInvalidMaterialsFactory(invalidMaterialOptions, {
    customerNumber: defaultMaterialOption.customerNumber,
    siteNumber: selectedSite.shippingAddress.siteNumber,
    shippingType: ShippingType.COLLECT
  })

  const incTooltipTitle = t('orderIntake.maxTruckCapacity', {
    capacity: maxCapacity,
    capacityUom: unitOfMeasure
  })

  const getTooltip = (
    testId: 'dec' | 'inc',
    field: ControllerRenderProps<MaterialOrderForm, `details.quantity`>
  ) =>
    (testId === 'dec' ? minCapacity : maxCapacity) === field.value
      ? ({
          title: testId === 'dec' ? decTooltipTitle : incTooltipTitle,
          placement: 'bottom'
        } as const)
      : undefined

  const driverInstructionsRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (driverInstructionsRef.current) {
      driverInstructionsRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [driverInstructionsRef.current])

  return (
    <Stack spacing={2}>
      <Box>
        <ShippingAddress
          showInLine={true}
          headline={
            shippingType === ShippingType.DELIVER
              ? t('orderIntake.deliverTo')
              : t('orderIntake.collectionAddress')
          }
          name={selectedSite.shippingAddress.siteName}
          street={selectedSite.shippingAddress.street}
          postalCode={selectedSite.shippingAddress.postalCode}
          city={selectedSite.shippingAddress.city}
        />
      </Box>

      <Box>
        {!isTimeForOrderOver ? (
          <Stack spacing={2}>
            <Box>
              {validMaterialOptions.length === 1 && isEmpty(invalidMaterialOptions) ? (
                <DropdownItem
                  title={validMaterialOptions[0].material.materialDescription}
                  description={`${t('orderIntake.plant')} ${
                    validMaterialOptions[0].plant.plantName
                  }`.toUpperCase()}
                  ariaLabel={`Material filter option ${validMaterialOptions[0].material.materialEnteredNumber}`}
                  dataTestId={`material-filter-option-${validMaterialOptions[0].material.materialEnteredNumber}`}
                />
              ) : (
                <Controller
                  control={methods.control}
                  name="details.selectedMaterialOption"
                  render={({field}) => (
                    <SelectDropdown<OrderIntakeMaterialOptionPayload>
                      label={t('orderIntake.material')}
                      isSearchable
                      data-test-id="order-intake-material-dropdown"
                      options={[...validMaterialOptions, ...invalidMaterialOptions]}
                      value={field.value}
                      description={`${t('orderIntake.plant')} ${field.value?.plant
                        ?.plantName}`.toUpperCase()}
                      className={classes.dropdownContainer}
                      innerButtonClassName={classes.dropdownButton}
                      onPopupToggle={trackInvalidMaterials}
                      filterOption={(t, o) =>
                        searchInContent(t, selectIdentity(o)) ||
                        searchInContent(t, selectPlantName(o))
                      }
                      renderValue={selectIdentity}
                      onChange={(o) => {
                        methods.setValue('details.customerReference', o?.customerReference ?? '')
                        field.onChange(o)
                      }}
                    >
                      {(o, prop) =>
                        o.map((i, idx) => (
                          <MaterialDropdownItem
                            data-test-id={`dropdown-filter-option-${idx}`}
                            selected={selectIdentity(i) === selectIdentity(field.value)}
                            key={`${selectIdentity(i)}-${idx}`}
                            title={selectIdentity(i) ?? ''}
                            disabled={i.invalid}
                            onClick={() => prop?.onChange(i)}
                            hasDivider={idx + 1 < o.length}
                            topMessage={
                              idx === validMaterialOptions.length
                                ? t('orderIntake.orderMaterialByPhone')
                                : undefined
                            }
                            description={
                              shippingType === ShippingType.COLLECT
                                ? `${t('orderIntake.plant')} ${i.plant.plantName}`.toUpperCase()
                                : ''
                            }
                            ariaLabel={`Material filter option ${i.material.materialEnteredNumber}`}
                          />
                        ))
                      }
                    </SelectDropdown>
                  )}
                />
              )}
            </Box>
            <Box justifyContent={{xs: 'left', sm: 'space-around'}}>
              {defaultMaterialOption.material.quantityType !== QuantityType.Load ? (
                <Controller
                  control={methods.control}
                  name="details.quantity"
                  rules={{
                    min: capacity.minCapacity,
                    max: capacity.maxCapacity
                  }}
                  render={({field}) => {
                    const isSameValue = minCapacity === maxCapacity && maxCapacity === field.value
                    return (
                      <Stack spacing={1} width="100%">
                        <NumericInputWithControls
                          data-test-id="truck-tonnage-amount"
                          name={field.name}
                          value={field.value}
                          ref={field.ref}
                          increment={capacity.quantityChangeIncrement ?? 1}
                          dontRoundValueToIncrement={!capacity.isRoundToChangeIncrement}
                          decimalsNumber={capacity.numberOfDecimals ?? 0}
                          title={
                            <>
                              {t('orderIntake.quantity')}{' '}
                              <QuantityUomToSymbolComponent uom={capacityUom} />
                            </>
                          }
                          disabled={isSameValue}
                          decrementTooltipProps={getTooltip('dec', field)}
                          incrementTooltipProps={getTooltip('inc', field)}
                          min={minCapacity}
                          max={maxCapacity}
                          onChange={field.onChange}
                        ></NumericInputWithControls>
                        {isSameValue && (
                          <Typography variant="caption" color="secondary">
                            {t('orderIntake.callToOrderLess')}
                          </Typography>
                        )}
                      </Stack>
                    )
                  }}
                />
              ) : (
                <Controller
                  control={methods.control}
                  name={`details.quantity`}
                  rules={{
                    min: capacity.minCapacity ? capacity.minCapacity : 1,
                    max: capacity.maxCapacity ? capacity.maxCapacity : 1
                  }}
                  render={({field}) => (
                    <FullWidthStack direction="column" spacing={1}>
                      <NumericInputWithControls
                        data-test-id="order-request-change-load"
                        name={field.name}
                        value={field.value}
                        ref={field.ref}
                        title={t('orderIntake.changeLoad')}
                        decrementTooltipProps={getTooltip('dec', field)}
                        incrementTooltipProps={getTooltip('inc', field)}
                        min={capacity.minCapacity ? capacity.minCapacity : 1}
                        max={capacity.maxCapacity ? capacity.maxCapacity : 1}
                        onChange={field.onChange}
                      />
                      <Typography variant="caption">
                        <Info style={{height: '11px'}} />
                        {t('required')}
                      </Typography>
                    </FullWidthStack>
                  )}
                />
              )}
            </Box>
          </Stack>
        ) : (
          <Box ml={2}>
            <InfoTextWithIcon
              text={t('orderIntake.latestDeliveryTimePassed')}
              data-test-id="order-intake-time-for-order-over"
            />
          </Box>
        )}
      </Box>

      <Box marginTop={4}>
        <MaterialSectionHeader>{t('orderIntake.customerReference')}</MaterialSectionHeader>
        <Features name="OrderIntakeCustomerReferenceField">
          <Box marginTop={2}>
            <Controller
              control={methods.control}
              name="details.customerReference"
              rules={{
                maxLength: 35,
                validate: (value) => {
                  return isValidCustomerReference(value)
                }
              }}
              render={({field, fieldState}) => {
                return (
                  <BorderedTextField
                    data-test-id="customer-reference-text-field-summary"
                    name={field.name}
                    label={t('orderIntake.customerReference')}
                    placeholder={t('orderIntake.customerReferencePlaceholder')}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e)
                      setActiveSelectedCustomerReference(e.target.value)
                    }}
                    onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                    disabled={isOrderRequestsCacheLoading}
                    required={true}
                    inputProps={{maxLength: 35}}
                    error={!!fieldState.error}
                  />
                )
              }}
            />
          </Box>
        </Features>
      </Box>

      <Box marginTop={2}>
        <Features name="DriverInstructions">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1" style={{fontWeight: 600}}>
              {t('orderIntake.addDriverInstruction')}
            </Typography>
            <Switch
              aria-label=""
              size="medium"
              data-test-id="order-intake-editor-driver-instructions"
              checked={enabledDriverInstructions}
              onChange={(_, checked) => handleEnabledDriverInstructions(checked)}
            />
          </Box>

          {enabledDriverInstructions && (
            <>
              {isReadOnlyDriversInstructionsAvailable && (
                <>
                  <Typography variant="caption" color="secondary">
                    {t('orderIntake.driverInstructionsFromContract')}
                  </Typography>
                  <Box p={0.25} />
                  <Typography variant="body1" color="textPrimary">
                    {readOnlyDriversInstructions}
                  </Typography>
                </>
              )}

              {!showDriverInstructionsInput && additionalInstructionsMaxLength > 0 && (
                <Box display="flex" justifyContent="flex-start" ref={driverInstructionsRef}>
                  <ActionButton
                    data-test-id="driver-instruction-add-more-details-button"
                    onClick={() => setShowDriverInstructionsInput(true)}
                    icon={<AddCircle color="primary" />}
                  >
                    {t('orderIntake.addMoreDetails')}
                  </ActionButton>
                </Box>
              )}
              {showDriverInstructionsInput && (
                <>
                  <Controller
                    control={methods.control}
                    name={`details.driverInstructions`}
                    render={({field}) => (
                      <BorderedTextField
                        data-test-id="driver-instruction-text-field"
                        name={field.name}
                        label={t('orderIntake.driverInstructions')}
                        onChange={(e) => {
                          field.onChange(e)
                          onDriverInstructionsChange(e)
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '') {
                            setResolvedDefaultDriverInstructions('')
                            setDefaultDriverInstructions('')
                            methods.setValue('details.driverInstructions', '', {
                              shouldDirty: true
                            })
                          }
                        }}
                        value={field.value}
                        defaultValue={resolvedDefaultDriverInstructions}
                        placeholder={t('orderIntake.driverInstructions')}
                        multiline
                        minRows={4}
                        maxRows={6}
                        onKeyDown={(e) => e.code === 'Enter' && e.stopPropagation()}
                        inputProps={{
                          maxLength: isReadOnlyDriversInstructionsAvailable
                            ? additionalInstructionsMaxLength
                            : DRIVER_INSTRUCTIONS_MAX_LENGTH
                        }}
                        helperText={`${field.value?.length ?? 0} / ${
                          isReadOnlyDriversInstructionsAvailable
                            ? additionalInstructionsMaxLength
                            : DRIVER_INSTRUCTIONS_MAX_LENGTH
                        }`}
                        error={driverInstructionsInputInvalid}
                      />
                    )}
                  />
                  <Typography
                    data-test-id="driver-instructions-invalid-characters-error-message"
                    variant="caption"
                    color="error"
                    style={{visibility: driverInstructionsInputInvalid ? 'visible' : 'hidden'}}
                  >
                    {t('orderIntake.driverInstructionsInvalidCharacters')}
                  </Typography>
                </>
              )}
            </>
          )}
        </Features>
      </Box>
    </Stack>
  )
}
