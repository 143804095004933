import {Logos} from '@hconnect/uikit'
import HubLogoSvgCompact from '@hconnect/uikit/src/common/assets/Hub-Logo-Compact-Negative.svg'

import {makeStyles} from '@material-ui/core/styles'
import {FC} from 'react'
import {Link} from 'react-router-dom'

import {useBranding} from '../Organisms/Branding'

const useStyles = makeStyles((theme) => ({
  logos: {
    margin: `0 ${theme.spacing(2)}px 0 0`,
    fontSize: '1.145em'
  },
  withoutBrandingLogo: {
    fontSize: '1.145em',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  hubLogo: {
    '&&': {
      paddingLeft: 0
    }
  }
}))

const HeaderLogo: FC<{withoutBranding?: boolean}> = ({withoutBranding = false}) => {
  const {logos, withoutBrandingLogo, hubLogo} = useStyles()
  const {nationalLogoUrl, NationalLogo, localLogoUrl, LocalLogo} = useBranding()

  return (
    <Link to="/">
      <Logos className={withoutBranding ? withoutBrandingLogo : logos}>
        <img src={HubLogoSvgCompact} alt="Hub" data-test-id="logo-hub" className={hubLogo} />
        {nationalLogoUrl && !withoutBranding && <NationalLogo data-test-id="logo-national" />}
        {localLogoUrl && !withoutBranding && <LocalLogo data-test-id="logo-local" />}
      </Logos>
    </Link>
  )
}

export default HeaderLogo
