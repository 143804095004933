import {Slide, Theme} from '@mui/material'
import classnames from 'classnames'
import {useEffect, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

const topHeight = 64
const useStyles = makeStyles()((theme: Theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(0, 4, 4, 4),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 1, 1, 1)
    }
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr'
    }
  },
  card: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.05)',
    background: 'white',
    color: '#333333',
    border: '1px solid transparent',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2)
    },
    height: 'fit-content'
  },
  slide: {
    position: 'absolute',
    overflowX: 'clip',
    right: 0,
    paddingLeft: theme.spacing(1)
  },
  sticky: {
    position: 'sticky',
    top: topHeight,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    width: 'calc(33.33vw + 8px)',
    maxHeight: `calc(100vh - ${topHeight}px)`,
    overflowY: 'auto',
    [theme.breakpoints.down('lg')]: {
      height: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    }
  }
}))

interface ResponsivePageProps {
  header: React.ReactNode
  mainContent: React.ReactNode
  sideContent?: React.ReactNode
  overlayContent?: React.ReactNode
}

export const ResponsivePage: React.FC<ResponsivePageProps> = ({
  header,
  mainContent,
  sideContent,
  overlayContent
}) => {
  const {classes} = useStyles()
  const containerRef = useRef<HTMLDivElement>(null)
  const [slideHeight, setSlideHeight] = useState(0)

  useEffect(() => {
    if (!containerRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      setSlideHeight(containerRef.current?.clientHeight || 0)
    })
    resizeObserver.observe(containerRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  return (
    <div className={classes.layout}>
      {header}
      <div className={classes.container} ref={containerRef}>
        <div className={classes.card}>{mainContent}</div>
        {sideContent && <div className={classes.card}>{sideContent}</div>}
        <div className={classes.slide} style={{height: slideHeight}}>
          <Slide
            direction="left"
            in={overlayContent !== undefined}
            mountOnEnter
            unmountOnExit
            container={containerRef.current}
          >
            <div className={classnames(classes.card, classes.sticky)}>{overlayContent}</div>
          </Slide>
        </div>
      </div>
    </div>
  )
}
