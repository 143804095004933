import {Search} from '@mui/icons-material'
import {InputAdornment, TextField} from '@mui/material'
import {SearchBoxProps} from './types'

export const SearchBox = ({
  classes,
  label,
  dark,
  searchTerm,
  onLight,
  t,
  setSearchTerm
}: SearchBoxProps) => {
  return (
    <div className={classes.searchContainer}>
      <TextField
        label={t('dropdown.searchList')}
        variant="standard"
        autoFocus
        fullWidth
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value)
        }}
        InputLabelProps={{
          classes: {
            root: classes.searchInputLabel,
            focused: onLight
              ? classes.searchInputLabelFocusedOnLight
              : classes.searchInputLabelFocused
          }
        }}
        InputProps={{
          inputProps: {
            'aria-label': `${label} lookup input`,
            'data-test-id': 'input-search'
          },
          classes: {
            root: onLight ? classes.searchInputOnLight : classes.searchInput,
            underline: onLight ? classes.searchInputUnderlineOnLight : classes.searchInputUnderline
          },
          startAdornment: (
            <InputAdornment
              position="start"
              className={
                dark
                  ? `${classes.inputAdornmentOnDark} ${classes.inputAdornment}`
                  : classes.inputAdornment
              }
              aria-label={`${label} search icon`}
            >
              <Search fontSize="small" style={{color: 'inherit'}} />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}
