import {
  ContentSnippet,
  createSnippet,
  Customer,
  getContentSnippetByPlantId,
  updateSnippet,
  User
} from '@hconnect/apiclient'
import {useRolesBusinessLogic} from '@hconnect/common/Invite/hooks/useRoles'
import {DataTable, Page, Typography, withRoute} from '@hconnect/uikit'
import {Box, Paper} from '@material-ui/core'
import find from 'lodash/find'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {api} from '../../../../../App.global'
import {useContainerStyles} from '../../../../../common/styles/containerStyles'
import {TitleNavigation} from '../../../../../Molecules/TitleNavigation'
import {selectSelectedCustomerFilter} from '../../../../../Organisms/Customers'
import {AppState} from '../../../../../Root.store'
import {QCData, useMaterialCertificatesForQC} from '../../hooks'
import {MaterialCertificate} from '../../types'
import {getEmptyMessage, getTableItems} from '../LettersOfCertification.utils'
import {ManageCertificateColumnns} from '../ManageCertificates.columns'

import {PlantInformation} from './Components/PlantInformation'
import {PlantSignature} from './Components/PlantSignature'
import {Popover} from './Components/Popover'
import Content from '../../../../../Molecules/Content'

export const ManageCertificatesRoute = '/manageCertificates'

const TYPE_TITLETEXT = 'plantQCTitleText'
const TYPE_NAMETEXT = 'plantQCNameText'
const TYPE_MATERIALTEXT = 'plantMaterialText'

// eslint-disable-next-line max-statements
const ManageCertificate: React.FC = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const history = useHistory()
  const userProfile = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const country = userProfile?.country ?? ''
  const customer = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )
  const {isQCSupervisor, getDatascopePropertyFromRole} = useRolesBusinessLogic()
  const qcSupervisor = isQCSupervisor
  const plantIds = getDatascopePropertyFromRole('QC_SUPERVISOR', 'plantIds')
  const {data: QCResponse, isFetching} = useMaterialCertificatesForQC({
    limit: 1500,
    enabled: qcSupervisor,
    plantIds,
    customer
  })
  const {materialCertificates, qcDataItems} = QCResponse || {}
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedMaterialCertificate, setSelectedMaterialCertificate] =
    useState<MaterialCertificate>()

  const [snippets, setSnippets] = useState<ContentSnippet[]>()
  const [isSnippetsLoading, setSnippetsLoading] = useState(false)
  const [selectedPlantId, setSelectedPlantId] = useState('')
  const [selectedPlantItem, setSelectedPlantItem] = useState<QCData>()

  const [address, setAddress] = useState('')
  const [isAddressLoading, setAddressLoading] = useState(false)
  const [isAddressSaveError, setIsAddressSaveError] = useState<boolean>(false)
  const [isAddressSaveSuccess, setIsAddressSaveSuccess] = useState<boolean>(false)

  const [signature, setSignature] = useState('')
  const [isSignatureLoading, setSignatureLoading] = useState(false)
  const [isSignatureSaveError, setIsSignatureSaveError] = useState<boolean>(false)
  const [isSignatureSaveSuccess, setIsSignatureSaveSuccess] = useState<boolean>(false)

  const [materialDescription, setMaterialDescription] = useState('')
  const [isMaterialDescriptionLoading, setMaterialDescriptionLoading] = useState(false)
  const [isMaterialDescriptionSaveError, setIsMaterialDescriptionSaveError] =
    useState<boolean>(false)
  const [isMaterialDescriptionSaveSuccess, setIsMaterialDescriptionSaveSuccess] =
    useState<boolean>(false)
  const {
    classes: {pageComponentContainer, pageContainer}
  } = useContainerStyles()
  const validPlantsForDropdown = qcDataItems

  useEffect(() => {
    if (!selectedPlantId && validPlantsForDropdown && validPlantsForDropdown[0]) {
      setSelectedPlantId(validPlantsForDropdown[0].plantId)
      setSelectedPlantItem(
        find(validPlantsForDropdown, {plantId: validPlantsForDropdown[0].plantId})
      )
    }
  }, [selectedPlantId, validPlantsForDropdown])

  const getSnippets = async () => {
    if (!selectedPlantId) {
      return
    }

    try {
      setSnippetsLoading(true)
      const result = await getContentSnippetByPlantId(api)(selectedPlantId)
      setSnippets(result.data)
    } catch {
      // do nothing :(
    } finally {
      setSnippetsLoading(false)
    }
  }

  useEffect(() => {
    void getSnippets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlantId])

  useEffect(() => {
    const address = snippets?.find((snippet) => snippet.type === TYPE_TITLETEXT)?.body

    if (address) {
      setAddress(address)
    }

    const signature = snippets?.find((snippet) => snippet.type === TYPE_NAMETEXT)?.body

    if (signature) {
      setSignature(signature)
    }
  }, [snippets])

  const handleChangePlant = (plantId: string) => {
    setSelectedPlantId(plantId)
    setSelectedPlantItem(find(qcDataItems, {plantId: plantId}))
    setIsAddressSaveError(false)
    setIsAddressSaveSuccess(false)
    setIsSignatureSaveError(false)
    setIsSignatureSaveSuccess(false)
    setIsMaterialDescriptionSaveError(false)
    setIsMaterialDescriptionSaveSuccess(false)
    setAddress('')
    setSignature('')
  }

  const handleAddressSave = async () => {
    try {
      setAddressLoading(true)
      setIsAddressSaveError(false)
      setIsAddressSaveSuccess(false)
      const snippet = snippets?.find((snippet) => snippet.type === TYPE_TITLETEXT)

      if (snippet) {
        await updateSnippet(api)({
          id: snippet?.id,
          type: TYPE_TITLETEXT,
          body: address,
          country: snippet.country,
          language: snippet.language,
          plantId: selectedPlantId
        })
      } else {
        await createSnippet(api)({
          type: TYPE_TITLETEXT,
          body: address,
          country,
          language,
          plantId: selectedPlantId
        })
      }
      void getSnippets()
    } catch (error) {
      setIsAddressSaveError(true)
      console.error({error})
    } finally {
      setIsAddressSaveSuccess(true)
      setAddressLoading(false)
    }
  }

  const handleMaterialDescriptionSave = async () => {
    try {
      setMaterialDescriptionLoading(true)
      setIsMaterialDescriptionSaveError(false)
      setIsMaterialDescriptionSaveSuccess(false)
      if (!selectedMaterialCertificate) {
        throw new Error('no certificate selected')
      }

      const snippet = snippets?.find(
        (snippet) => snippet.materialNumber === selectedMaterialCertificate?.materialNumber
      )

      if (snippet) {
        await updateSnippet(api)({
          id: snippet?.id,
          type: TYPE_MATERIALTEXT,
          body: materialDescription,
          country: snippet.country,
          language: snippet.language,
          plantId: selectedPlantId,
          materialNumber: selectedMaterialCertificate.materialNumber
        })
      } else {
        await createSnippet(api)({
          type: TYPE_MATERIALTEXT,
          body: materialDescription,
          country,
          language,
          plantId: selectedPlantId,
          materialNumber: selectedMaterialCertificate.materialNumber
        })
      }
      void getSnippets()
    } catch (error) {
      setIsMaterialDescriptionSaveError(true)
      console.error({error})
    } finally {
      setIsMaterialDescriptionSaveSuccess(true)
      setMaterialDescriptionLoading(false)
    }
  }

  const handleSignatureSave = async () => {
    try {
      setSignatureLoading(true)
      setIsSignatureSaveError(false)
      setIsSignatureSaveSuccess(false)
      const snippet = snippets?.find((snippet) => snippet.type === TYPE_NAMETEXT)

      if (snippet) {
        await updateSnippet(api)({
          id: snippet?.id,
          type: TYPE_NAMETEXT,
          body: signature,
          country: snippet.country,
          language: snippet.language,
          plantId: selectedPlantId
        })
      } else {
        await createSnippet(api)({
          type: TYPE_NAMETEXT,
          body: signature,
          country,
          language,
          plantId: selectedPlantId
        })
      }
      void getSnippets()
    } catch (error) {
      setIsSignatureSaveError(true)
      console.error({error})
    } finally {
      setIsSignatureSaveSuccess(true)
      setSignatureLoading(false)
    }
  }

  const handleEditCertificateClick = (certificate: MaterialCertificate) => {
    setSelectedMaterialCertificate(certificate)

    const materialSnippet = snippets?.find(
      (snippet) => snippet.materialNumber === certificate.materialNumber
    )

    if (materialSnippet) {
      setMaterialDescription(materialSnippet.body)
    } else {
      setMaterialDescription('')
    }

    setIsModalOpen(true)
  }

  const columnsConfig = ManageCertificateColumnns({handleEditClick: handleEditCertificateClick})

  if (!validPlantsForDropdown) {
    return null
  }

  return (
    <Content style={{padding: '4px 8px 8px 8px'}}>
      <div className={pageContainer}>
        <TitleNavigation
          title={t('certificate.titleDefault')}
          onClick={() => history.goBack()}
          ariaLabel="Manage Certificate Title"
        />

        <Page boxed variant="sheet" className={pageComponentContainer}>
          <Paper data-test-id="manage-certificate-page">
            <Box padding={4}>
              <Typography variant="h1">{t('certificate.editCertificate.title')}</Typography>
              <Box p={2} />
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Typography variant="h2">{t('certificate.editCertificate.plants')}</Typography>
                <Box p={2} />
                <Box display="flex" flexDirection="row">
                  <PlantInformation
                    validPlantsForDropdown={validPlantsForDropdown}
                    isFetching={isFetching}
                    selectedPlantItem={selectedPlantItem}
                    address={address}
                    setAddress={setAddress}
                    isAddressSaveError={isAddressSaveError}
                    isSnippetsLoading={isSnippetsLoading}
                    handleAddressSave={handleAddressSave}
                    isAddressSaveSuccess={isAddressSaveSuccess}
                    isAddressLoading={isAddressLoading}
                    handleChangePlant={handleChangePlant}
                  />
                  <Box p={4} />
                  <PlantSignature
                    isFetching={isFetching}
                    signature={signature}
                    setSignature={setSignature}
                    isSignatureSaveError={isSignatureSaveError}
                    isSignatureSaveSuccess={isSignatureSaveSuccess}
                    handleSignatureSave={handleSignatureSave}
                    isSignatureLoading={isSignatureLoading}
                    isSnippetsLoading={isSnippetsLoading}
                  />
                </Box>
              </Box>
              <Box p={4} />
              <Box>
                <Typography variant="h2">
                  {t('certificate.editCertificate.cementTypeTitle')}
                </Typography>
                <Box p={4} />
                <DataTable
                  data-test-id="certificate-data-table"
                  columns={columnsConfig}
                  data={getTableItems(selectedPlantId, materialCertificates)}
                  keyExtractor={(item: MaterialCertificate) =>
                    `${item.plantId}-${item.materialNumber}`
                  }
                  loading={isFetching}
                  variant="compact"
                  emptyMessage={
                    <Typography variant="caption">{getEmptyMessage(isFetching, t)}</Typography>
                  }
                />
              </Box>
              <Box p={2} />
            </Box>
          </Paper>
          <Popover
            handleMaterialDescriptionSave={handleMaterialDescriptionSave}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setIsMaterialDescriptionSaveError={setIsMaterialDescriptionSaveError}
            setIsMaterialDescriptionSaveSuccess={setIsMaterialDescriptionSaveSuccess}
            materialDescription={materialDescription}
            isSnippetsLoading={isSnippetsLoading}
            selectedMaterialCertificate={selectedMaterialCertificate}
            isMaterialDescriptionSaveError={isMaterialDescriptionSaveError}
            isMaterialDescriptionSaveSuccess={isMaterialDescriptionSaveSuccess}
            setMaterialDescription={setMaterialDescription}
            isMaterialDescriptionLoading={isMaterialDescriptionLoading}
          />
        </Page>
      </div>
    </Content>
  )
}

// eslint-disable-next-line import/no-default-export
export default withRoute({
  path: ManageCertificatesRoute,
  hideInMainNav: true,
  routeProps: {exact: true},
  label: 'Manage Certificates'
})(ManageCertificate)
