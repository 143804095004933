import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {ErrorPaper, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {TFunction} from 'i18next'
import {debounce} from 'lodash'
import get from 'lodash/get'
import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {AppState} from '../../Root.store'
import {Payer, PayersStateType, fetchPayers} from '../Payers'
import {CustomersPayers, selectCustomersPayers} from '../Payers/Payers.selectors'

import {fetchCustomers} from './Customers.action'
import {selectCustomers, selectSelectedCustomerFilter} from './Customers.selector'
import {Customer} from './Customers.types'
import {useFeaturesState} from '../Features'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'

const useStyles = makeStyles(() => ({
  button: {
    width: 222
  },
  long: {
    width: 'auto'
  }
}))

interface SelectItemType {
  customer?: CustomersPayers
  payer?: Payer
  onLight?: boolean
  t: TFunction
}

const SelectItem: React.FC<SelectItemType> = ({customer, payer, onLight, t}) => {
  return (
    <>
      {payer ? (
        <Typography
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('payers.payersDropdown.payedBy')}
        </Typography>
      ) : null}
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {customer ? customer.customerName : payer?.payerName}
      </Typography>
      <Typography variant="caption" customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}>
        {t('customers.customerDropdown.label')}{' '}
        {customer ? customer.customerNumber : payer?.payerNumber}
      </Typography>
    </>
  )
}

const showDropdown = (customerPayers: CustomersPayers[] | Customer[]): boolean =>
  (customerPayers && customerPayers.length > 1) ||
  (customerPayers &&
    customerPayers.length === 1 &&
    'items' in customerPayers[0] &&
    customerPayers[0].items &&
    customerPayers[0].items.length > 1)

interface CustomerDropdownType {
  label?: string
  dark?: boolean
  long?: boolean
  onLight?: boolean
  onCustomerChange: (val: CustomersPayers) => void
  chosenValueType?: 'customer' | 'payer' | null
  loading?: boolean
  showTitleOnlyEnabled?: boolean
  customersOnly?: boolean
  t: TFunction
  smallScreen?: boolean
}
const CustomerDropdown: React.FC<CustomerDropdownType> = ({
  label,
  onCustomerChange,
  dark,
  long,
  onLight,
  loading,
  showTitleOnlyEnabled = false,
  customersOnly,
  t,
  smallScreen = false,
  ...props
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {getFeature} = useFeaturesState()
  const useHaulierView = getFeature('UseHaulierView')
  // props connected to store
  const customers = useSelector<AppState, Customer[]>((state) =>
    get(selectCustomers(state), 'customers')
  )
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const customerPayers = useSelector<AppState, CustomersPayers[]>((state) =>
    selectCustomersPayers(state)
  )
  const isFetching = useSelector<AppState, boolean>((state) =>
    get(selectCustomers(state), 'isFetching')
  )
  const error = useSelector<AppState, any>((state) => get(selectCustomers(state), 'error'))
  const selectedCustomer = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )
  const payersState = useSelector<AppState, PayersStateType>((state) => state.payers)
  const {entities: payersEntities} = payersState
  const debounceTypeaheadSearchTerm = useCallback(
    debounce(
      (term) =>
        trackEvent('typeaheadSearchTerm', {
          product: 'hub',
          type: 'customer',
          searchTerm: term
        }),
      200
    ),
    []
  )

  useEffect(() => {
    if (!customers && !isFetching) {
      dispatch(fetchCustomers(useHaulierView, user?.user_id))
    }
    if (
      !customersOnly &&
      customers?.length &&
      !isFetching &&
      selectedCustomer &&
      !payersEntities[selectedCustomer?.customerId]
    ) {
      dispatch(fetchPayers(selectedCustomer?.customerId))
    }
  }, [dispatch, customers, selectedCustomer, customersOnly, isFetching, user, payersEntities])

  const options = customersOnly ? customers : customerPayers

  const selectedItem = selectedCustomer ? selectedCustomer : null

  if (!showDropdown(options) && !loading) {
    return showTitleOnlyEnabled ? <Box> {selectedCustomer?.customerName || ''}</Box> : null
  }

  return (
    <>
      <SelectDropdown
        data-test-id="customer-dropdown"
        dark={dark}
        long={long}
        selectedProps={{
          className: long ? classes.long : classes.button
        }}
        label={long ? '' : label || 'Select Account'}
        showError={!!error}
        errorRender={() => (
          <ErrorPaper
            variant="primaryDark"
            onRetry={() => dispatch(fetchCustomers(useHaulierView, user?.user_id))}
          />
        )}
        loading={loading}
        options={options}
        renderItem={(item: CustomersPayers) => (
          <SelectItem customer={item} onLight={onLight} t={t} />
        )}
        renderSubItem={(item: Payer) => <SelectItem payer={item} onLight={onLight} t={t} />}
        stringifyItem={(item: CustomersPayers) => `${item.customerName} ${item.customerNumber}`}
        stringifySubItem={(item: Payer) => `${item.payerName} ${item.payerNumber || ''}`}
        renderChosenItem={(item: CustomersPayers) => `${item.customerName}`}
        isDisabled={(item: CustomersPayers) => item.items && item.items.length > 1}
        selectedItem={selectedItem}
        identifierKey="customerId"
        onChange={onCustomerChange}
        onLight={onLight}
        onSearchTermChange={(term: string) => term && debounceTypeaheadSearchTerm(term)}
        smallScreen={smallScreen}
        {...props}
      />
      <Box mx={1} />
    </>
  )
}

export default CustomerDropdown
