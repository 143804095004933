import {TextButton, Typography} from '@hconnect/uikit'
import {Box, TextField, LinearProgress} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {QCData} from '../../../hooks'
import {MaterialCertificate} from '../../../types'
import {
  compareCertificatesByPlantName,
  getTextBoxHelperText
} from '../../LettersOfCertification.utils'
import {PlantSelectItem} from '../PlantSelectItem'
import {SelectDropdown} from '../../../../../../Molecules/SelectDropdown/SelectDropdown'

interface PlantInformationProps {
  isFetching: boolean
  isSnippetsLoading: boolean
  isAddressSaveError: boolean
  isAddressLoading: boolean
  isAddressSaveSuccess: boolean
  validPlantsForDropdown: QCData[]
  selectedPlantItem: QCData | undefined
  address: string

  setAddress(address: string): void
  handleAddressSave(): void
  handleChangePlant(plantId: string): void
}

export const PlantInformation: React.FC<PlantInformationProps> = ({
  isFetching,
  address,
  setAddress,
  isAddressSaveError,
  isSnippetsLoading,
  handleAddressSave,
  isAddressSaveSuccess,
  isAddressLoading,
  validPlantsForDropdown,
  handleChangePlant,
  selectedPlantItem
}) => {
  const {t} = useTranslation()
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true)
  const sortedPlants = validPlantsForDropdown.sort(compareCertificatesByPlantName)

  const handleAddressChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAddress(evt.target.value)
  }
  useEffect(() => {
    setIsDataLoading(isSnippetsLoading || isFetching)
  }, [isSnippetsLoading, isFetching])

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Typography variant="h3">{t('certificate.editCertificate.myPlant')}</Typography>
      <Box p={2} />
      <SelectDropdown
        label={t('certificate.plantsDropdown.label')}
        options={sortedPlants}
        loading={isFetching}
        renderItem={(item: MaterialCertificate) => <PlantSelectItem plantName={item.plantName} />}
        stringifyItem={(item: MaterialCertificate) => `${item.plantName}`}
        onChange={(item: MaterialCertificate) => handleChangePlant(item.plantId)}
        selectedItem={selectedPlantItem}
        identifierKey="plantId"
        onLight
      />
      <Box p={2} />
      <Box display="flex" flexDirection="column">
        {isDataLoading ? <LinearProgress data-test-id="loc-plant-loader" /> : null}
        <TextField
          multiline
          variant="filled"
          placeholder={isDataLoading ? '' : t('certificate.editCertificate.addressPlaceholder')}
          value={address}
          onChange={handleAddressChange}
          disabled={isSnippetsLoading || isFetching}
          error={isAddressSaveError}
          helperText={getTextBoxHelperText(isAddressSaveError, isAddressSaveSuccess, t)}
        />
        <Box p={1} />
        <TextButton
          color="secondary"
          variant="text"
          onClick={handleAddressSave}
          disabled={isAddressLoading || isFetching || !address}
        >
          {t('certificate.editCertificate.saveTitle')}
        </TextButton>
      </Box>
    </Box>
  )
}
