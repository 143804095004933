import {ErrorPaper, Page, TitleNav, Typography} from '@hconnect/uikit'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Divider,
  Grid,
  Switch,
  IconButton,
  Button,
  ClickAwayListener,
  useTheme
} from '@material-ui/core'
import {Close, ArrowForward, Share} from '@material-ui/icons'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import {useMediaQuery} from '@mui/material'
import clsx from 'clsx'
import React, {useState, useEffect, CSSProperties} from 'react'
import {useTranslation} from 'react-i18next'
import {useInView} from 'react-intersection-observer'
import {useHistory} from 'react-router-dom'

import {DEFAULT_CUSTOMER} from '../../../App.constants'
import {useMediaQueryBreakpoints} from '../../../common/useMediaQueryBreakpoints'
import {useRoleConfiguration} from '../hooks/useConfigurations'
import {
  useCustomerByNumberLogic,
  useCustomerBySearchTermLogic,
  useCustomerLogic
} from '../hooks/useCustomers'
import {useDefaultSettings} from '../hooks/useDefaultSettings'
import {useFeaturesForInvitation} from '../hooks/useFeatures'
import {usePayerByNumberLogic, usePayersLogic} from '../hooks/usePayers'
import {usePermissions} from '../hooks/usePermissions'
import {useProjects} from '../hooks/useProjects'
import {useCreateRoleRequestMutation} from '../hooks/useRoleRequestMutation'
import {useRolesLogic} from '../hooks/useRoles'
import {useSites} from '../hooks/useSites'
import {
  payerActionBasedPermissionsInitialValue,
  customerActionBasedPermissionsInitialValue,
  useRoleMapping
} from '../Invite.roleMatrix'
import {ActionBasedPermissionKeys, ActionBasedPermissions, SearchTypeKeys} from '../Invite.types'

import {LookupDropdown, Item} from './components/LookupDropdown'
import {
  ProjectsSitesModal,
  defaultState,
  ProjectsSitesState
} from './components/projectsSites/ProjectsSitesModal'
import {Option} from './components/virtualizedList/VirtualizedList'
import {CreateInvite} from './CreateInvite'
import {useInviteStyles} from './InviteStyles'
import {useQueryParamState} from './QueryParamStateHook'
import {SelectDropdown} from '../../../../src/Molecules/SelectDropdown/SelectDropdown'
import Content from '../../../Molecules/Content'
import SubHeader from '../../../Molecules/SubHeader'
/* eslint-disable complexity */

interface InviteCreatePageProps {
  loggedInUserId: string
}

export const InviteCreatePage = ({loggedInUserId}: InviteCreatePageProps) => {
  const classes = useInviteStyles()
  const theme = useTheme()
  const {t} = useTranslation()

  const {sm: isMobile} = useMediaQueryBreakpoints()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isTablet = useMediaQuery('(max-width:960px)')

  const [filter, setFilter] = useQueryParamState<{
    searchId?: string
    countryId?: string
    searchType?: SearchTypeKeys
  }>({
    searchId: undefined,
    countryId: undefined,
    searchType: undefined
  })

  const [enableUseCustomerLogic, setEnableUseCustomerLogic] = useState(false)

  useEffect(() => {
    setToggles({
      ...(filter.searchType === SearchTypeKeys.customer
        ? customerActionBasedPermissionsInitialValue
        : payerActionBasedPermissionsInitialValue)
    })
  }, [filter])

  const {
    hasCustomerOrPayerAssignments,
    isRolesLoading,
    customerIdsFromRole,
    payerIdsFromRole,
    hasOnlyPayerAssignments,
    hasOnlyCustomerAssignments,
    rolesToGenerate,
    countriesOfRoles,
    shouldFetchAllCustomersOrPayersForSalesAgent,
    salesAgentDataScopes
  } = useRolesLogic(filter.countryId)

  const customerSearch = filter.searchType === SearchTypeKeys.customer

  const roleRequestMutation = useCreateRoleRequestMutation()

  const {
    canSeeCreditLimit,
    canSeeInvoices,
    canSeeTestsAndCertificates,
    isCustomersByNameSearch,
    isLegalContactAndCanDoEverythingForCustomer,
    isLegalContactAndCanDoEverythingForPayer,
    isLoading: featuresLoading
  } = useFeaturesForInvitation()

  const {displayCustomerSimpleLookup, isLoading: permissionsLoading} =
    usePermissions(loggedInUserId)

  const enableCustomerByNameSearch =
    displayCustomerSimpleLookup &&
    isCustomersByNameSearch &&
    customerSearch &&
    shouldFetchAllCustomersOrPayersForSalesAgent

  const {isCustomerLoading, customerItems} = useCustomerLogic(
    customerIdsFromRole,
    hasCustomerOrPayerAssignments,
    shouldFetchAllCustomersOrPayersForSalesAgent ? salesAgentDataScopes : undefined,
    enableUseCustomerLogic
  )

  const enableUsePayersLogic =
    filter.searchType === SearchTypeKeys.payer &&
    (shouldFetchAllCustomersOrPayersForSalesAgent || hasCustomerOrPayerAssignments) &&
    !enableCustomerByNameSearch
  const {isPayerLoading, payerItems} = usePayersLogic(
    payerIdsFromRole,
    hasCustomerOrPayerAssignments,
    shouldFetchAllCustomersOrPayersForSalesAgent ? salesAgentDataScopes : undefined,
    enableUsePayersLogic
  )

  const enableUseCustomerBySearchTermLogic =
    !!filter.searchId && filter.searchType === SearchTypeKeys.customer && enableCustomerByNameSearch
  const {
    customSearchTermFound,
    customSearchTermNotFound,
    customerSearchTermItems,
    selectedSearchTermCustomer,
    isCustomerBySearchTermLoading
  } = useCustomerBySearchTermLogic(filter.searchId, enableUseCustomerBySearchTermLogic)

  const enableUsePayerByNumberLogic =
    !!filter.searchId && filter.searchType === SearchTypeKeys.payer && !enableCustomerByNameSearch
  const {isPayerByNumberLoading, payerFound, payerNotFound, selectedPayer} = usePayerByNumberLogic(
    filter.searchId,
    enableUsePayerByNumberLogic
  )

  const enableUseCustomerByNumberLogic =
    !!filter.searchId &&
    filter.searchType === SearchTypeKeys.customer &&
    !enableCustomerByNameSearch

  const {
    isCustomerByNumberLoading,
    customerFound,
    customerNotFound,
    selectedCustomer,
    allCustomers
  } = useCustomerByNumberLogic(filter.searchId, enableUseCustomerByNumberLogic, loggedInUserId)

  const {data: roleConfigurationList = [], isLoading: isRoleConfigurationListLoading} =
    useRoleConfiguration(loggedInUserId)

  const [toggles, setToggles] = useState<ActionBasedPermissions>({
    ...(filter.searchType === SearchTypeKeys.customer
      ? customerActionBasedPermissionsInitialValue
      : payerActionBasedPermissionsInitialValue)
  })

  const [allProjectsToggle, setAllProjectsToggle] = useState(true)

  const [modalState, setModalState] = useState<ProjectsSitesState>(defaultState)

  const customer = enableCustomerByNameSearch ? selectedSearchTermCustomer : selectedCustomer

  const projectAndSitesEnabled =
    !!customer?.customerId && filter.searchType === SearchTypeKeys.customer

  const {projects, isProjectsLoading} = useProjects(
    customer?.customerId,
    modalState.projectsIds,
    projectAndSitesEnabled
  )

  const {sites, isSitesLoading} = useSites(
    customer?.customerId,
    modalState.sitesIds,
    projectAndSitesEnabled
  )

  const {data: defaultSettings, isLoading: isDefaultSettingsLoading} = useDefaultSettings()

  const {hasProjectsEnabled, hasSitesEnabled, roleType} = useRoleMapping(
    toggles,
    filter.searchType,
    roleConfigurationList
  )

  const [linkUsageLimit, setLinkUsageLimit] = useState<number | undefined>()
  const [linkValidDays, setLinkValidDays] = useState<number | undefined>()

  useEffect(() => {
    if (!permissionsLoading && !featuresLoading && !isRolesLoading && filter.searchType) {
      const shouldUseCustomerLogic =
        filter.searchType === SearchTypeKeys.customer &&
        hasCustomerOrPayerAssignments &&
        !enableCustomerByNameSearch
      setEnableUseCustomerLogic(shouldUseCustomerLogic)
      setSelectedPayerOrCustomer(undefined)
    }
  }, [permissionsLoading, featuresLoading, filter.searchType])

  useEffect(() => {
    if (defaultSettings) {
      setLinkUsageLimit(defaultSettings?.defaultUsageLimit)
      setLinkValidDays(defaultSettings?.defaultExpireDays)
    }
  }, [defaultSettings])

  useEffect(() => {
    roleRequestMutation.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkUsageLimit, linkValidDays, modalState, filter.searchType, toggles])

  const isLoading =
    isCustomerByNumberLoading ||
    isCustomerLoading ||
    isCustomerBySearchTermLoading ||
    isPayerByNumberLoading ||
    isPayerLoading ||
    isRoleConfigurationListLoading ||
    isDefaultSettingsLoading ||
    isRolesLoading

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTypeKey = (event.target as HTMLInputElement).value as SearchTypeKeys
    setFilter({...filter, searchType: searchTypeKey})
  }

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (
      filter.searchType === SearchTypeKeys.customer &&
      event.target.name ===
        ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything.toString() &&
      checked
    ) {
      setToggles({
        canSeeOrderAndDeliveries: true,
        canCreateAndChangeOrders: true,
        canSeeInvoices: true,
        canSeeTestsAndCertificates: true,
        isTheLegalContactOfTheCustomerAndCanDoEverything: true
      })
    } else if (
      filter.searchType === SearchTypeKeys.customer &&
      event.target.name !==
        ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything.toString() &&
      !checked
    ) {
      setToggles({
        ...toggles,
        [event.target.name]: checked,
        isTheLegalContactOfTheCustomerAndCanDoEverything: checked
      })
    } else if (
      filter.searchType === SearchTypeKeys.payer &&
      event.target.name ===
        ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything.toString() &&
      checked
    ) {
      setToggles({
        canSeeInvoices: true,
        canSeeCreditLimitInformationAndMakePayments: checked,
        isTheLegalContactOfTheCustomerAndCanDoEverything: checked
      })
    } else {
      setToggles({...toggles, [ActionBasedPermissionKeys[event.target.name]]: checked})
    }
    setModalState(defaultState)
  }

  const [isMount, setIsMount] = useState(true)
  useEffect(() => {
    if (isMount) {
      setIsMount(false)
      return
    }
    setFilter({...filter, searchId: ''})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.searchType])

  const history = useHistory()
  const renderActionBasedPermissionToggles = () => (
    <Box mt={3} mb={3}>
      {Object.keys(toggles).map((toggle, index) => {
        if (
          filter.searchType === SearchTypeKeys.payer &&
          toggle ===
            ActionBasedPermissionKeys.canSeeCreditLimitInformationAndMakePayments.toString() &&
          !canSeeCreditLimit
        ) {
          return null
        }

        if (
          filter.searchType === SearchTypeKeys.payer &&
          toggle ===
            ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything.toString() &&
          !isLegalContactAndCanDoEverythingForPayer
        ) {
          return null
        }

        if (
          filter.searchType === SearchTypeKeys.customer &&
          toggle === ActionBasedPermissionKeys.canSeeInvoices.toString() &&
          !canSeeInvoices
        ) {
          return null
        }

        if (
          filter.searchType === SearchTypeKeys.customer &&
          toggle === ActionBasedPermissionKeys.canSeeTestsAndCertificates.toString() &&
          !canSeeTestsAndCertificates
        ) {
          return null
        }

        if (
          filter.searchType === SearchTypeKeys.customer &&
          toggle ===
            ActionBasedPermissionKeys.isTheLegalContactOfTheCustomerAndCanDoEverything.toString() &&
          !isLegalContactAndCanDoEverythingForCustomer
        ) {
          return null
        }

        const switchDisabled =
          (filter.searchType === SearchTypeKeys.customer &&
            toggle === ActionBasedPermissionKeys.canSeeOrderAndDeliveries.toString()) ||
          (filter.searchType === SearchTypeKeys.payer &&
            toggle === ActionBasedPermissionKeys.canSeeInvoices.toString())
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between" key={index}>
            <div>
              <Typography component="h3">{t(`actionBasedPermissions.${toggle}`)}</Typography>
            </div>
            <Switch
              data-test-id={`${toggle}-switch`}
              disabled={switchDisabled}
              disableRipple={true}
              classes={{
                root: classes.switchRoot,
                track: clsx({
                  [classes.trackSwitchBase]: true,
                  [classes.disabledTrack]: !toggles[toggle],
                  [classes.enabledTrack]: toggles[toggle]
                }),
                thumb: classes.thumb,
                switchBase: clsx({
                  [classes.disabledSwitchBase]: !toggles[toggle],
                  [classes.enabledSwitchBase]: toggles[toggle]
                })
              }}
              checked={toggles[toggle]}
              onChange={handleToggleChange}
              name={toggle}
              inputProps={{'aria-label': 'secondary checkbox'}}
            />
          </Box>
        )
      })}
    </Box>
  )

  const goToOverview = () => history.push('/overview')

  useEffect(() => {
    setModalState({
      ...modalState,
      all: allProjectsToggle
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjectsToggle])

  const renderSelectProjectsAndSites = () => {
    if (isProjectsLoading || isSitesLoading) {
      return (
        <Box mt={3}>
          <CircularProgress data-test-id="projects-create-invite-loader" />
        </Box>
      )
    }
    if ((hasProjectsEnabled || hasSitesEnabled) && filter.searchType === SearchTypeKeys.customer) {
      return (
        <Box>
          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography style={{marginBottom: '20px', marginTop: '20px'}}>
              {t('invitations.accessToAllProjects')}
            </Typography>
            <Switch
              aria-label="all projects switch"
              size="medium"
              data-test-id="accessAllProjectsSwitch"
              checked={allProjectsToggle}
              classes={{
                root: classes.switchRoot,
                track: clsx({
                  [classes.trackSwitchBase]: true,
                  [classes.disabledTrack]: !allProjectsToggle,
                  [classes.enabledTrack]: allProjectsToggle
                }),
                thumb: classes.thumb,
                switchBase: clsx({
                  [classes.disabledSwitchBase]: !allProjectsToggle,
                  [classes.enabledSwitchBase]: allProjectsToggle
                })
              }}
              onChange={() => setAllProjectsToggle(!allProjectsToggle)}
            />
          </Box>
          {!allProjectsToggle && (
            <ProjectsSitesModal
              modalState={modalState}
              setModalState={setModalState}
              hasSitesEnabled={hasSitesEnabled}
              hasProjectsEnabled={hasProjectsEnabled}
              projectsData={projects}
              sitesData={sites}
              loading={isSitesLoading && isProjectsLoading}
            />
          )}
        </Box>
      )
    } else {
      return (
        <Box my={2}>
          <Typography variant="caption">{t('roleAssignment.allProjectsAndSites')}</Typography>
        </Box>
      )
    }
  }

  const renderCustomerOrPayerName = () => {
    if (selectedPayer || customer) {
      const name =
        filter.searchType === SearchTypeKeys.payer
          ? selectedPayer?.payerName
          : customer?.customerName
      return (
        <Box mt={4}>
          <Typography>
            {t('invitations.InviteLinkTo') + ' '}
            {name}
          </Typography>
        </Box>
      )
    }
  }

  const renderRightColumn = () => {
    if (
      !filter.searchId ||
      !defaultSettings ||
      !rolesToGenerate ||
      (filter.searchType === SearchTypeKeys.customer &&
        (enableCustomerByNameSearch ? customSearchTermNotFound : customerNotFound)) ||
      (filter.searchType === SearchTypeKeys.payer && payerNotFound) ||
      isLoading ||
      (countriesOfRoles.length > 1 && !filter.countryId)
    ) {
      return null
    }
    const customerOrPayerId =
      filter.searchType === SearchTypeKeys.customer ? customer?.customerId : selectedPayer?.payerId

    return (
      <CreateInvite
        userId={loggedInUserId}
        selectedCountryId={
          countriesOfRoles.length === 1 ? countriesOfRoles[0] : filter.countryId ?? ''
        }
        modalState={modalState}
        customerOrPayerId={
          enableCustomerByNameSearch ? selectedSearchTermCustomer?.customerId : customerOrPayerId
        }
        searchType={filter.searchType}
        rolesToGenerate={rolesToGenerate}
        defaultSettings={defaultSettings}
        roleType={roleType}
        toggles={toggles}
        projectsAndSitesModal={renderSelectProjectsAndSites()}
        roleRequestMutation={roleRequestMutation}
        linkValidDays={linkValidDays}
        setLinkValidDays={setLinkValidDays}
        linkUsageLimit={linkUsageLimit}
        setLinkUsageLimit={setLinkUsageLimit}
      />
    )
  }

  const getLookupLabel = () => {
    const customerDropdown = customerItems && customerItems.length > 0
    const payerDropdown = payerItems && payerItems.length > 0

    if (filter.searchType === SearchTypeKeys.customer) {
      if (customerDropdown) {
        return t('invitations.selectCustomer')
      } else {
        return t('invitations.searchCustomer')
      }
    }
    if (filter.searchType === SearchTypeKeys.payer) {
      if (payerDropdown) {
        return t('invitations.selectPayer')
      } else {
        return t('invitations.searchPayer')
      }
    }
    return ''
  }

  const renderRadioButtonGroup = () => {
    return (
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={filter.searchType || ''}
        onChange={handleRadioChange}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '60%',
          paddingTop: '15px'
        }}
      >
        <FormControlLabel
          value={SearchTypeKeys.customer}
          data-test-id="invitations.customer.toggle"
          control={
            <Radio
              classes={{root: classes.radio}}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="inherit"
                  htmlColor={hasOnlyCustomerAssignments ? '#7f9ba6' : '#0051A5'}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  fontSize="inherit"
                  htmlColor={hasOnlyCustomerAssignments ? '#7f9ba6' : '#0051A5'}
                />
              }
            />
          }
          label={t('invitations.customerLevel')}
          style={{paddingRight: '10px'}}
          disabled={hasOnlyPayerAssignments}
        />
        <FormControlLabel
          value={SearchTypeKeys.payer}
          data-test-id="invitations.payer.toggle"
          control={
            <Radio
              classes={{root: classes.radio}}
              icon={
                <RadioButtonUncheckedIcon
                  fontSize="inherit"
                  htmlColor={
                    hasOnlyCustomerAssignments || !hasCustomerOrPayerAssignments
                      ? '#7f9ba6'
                      : '#0051A5'
                  }
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  fontSize="inherit"
                  htmlColor={
                    hasOnlyCustomerAssignments || !hasCustomerOrPayerAssignments
                      ? '#7f9ba6'
                      : '#0051A5'
                  }
                />
              }
            />
          }
          label={t('invitations.payerLevel')}
          disabled={hasOnlyCustomerAssignments || !hasCustomerOrPayerAssignments}
        />
      </RadioGroup>
    )
  }

  const renderTogglesAndProjectsAndSites = () => {
    if (isLoading) {
      return (
        <Box mt={3}>
          <CircularProgress data-test-id="create-invite-loader" />
        </Box>
      )
    } else if (
      (customerSearch && (enableCustomerByNameSearch ? customSearchTermFound : customerFound)) ||
      (!customerSearch && payerFound)
    ) {
      return (
        <Box mt={3}>
          <Grid item xs={12}>
            <Typography className={classes.header} gutterBottom>
              {t('invite.create.userPermissions')}
            </Typography>
            <Divider />
            {renderActionBasedPermissionToggles()}
            <Typography gutterBottom className={classes.header}>
              {t('invitations.whereCanUsePermission')}
            </Typography>
            <Divider color="soft" />
            {renderSelectProjectsAndSites()}
          </Grid>
        </Box>
      )
    } else {
      return null
    }
  }

  interface SelectItemType {
    item: Item
    onLight?: boolean
  }

  const SelectItem: React.FC<SelectItemType> = ({item, onLight}) => {
    return (
      <>
        <Typography
          component="div"
          variant="body1"
          color={onLight ? 'textPrimary' : 'textSecondary'}
        >
          {item.label}
        </Typography>
        <Typography
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('customers.customerDropdown.label')} {item.value}
        </Typography>
      </>
    )
  }

  const selectedItem = customerSearch
    ? {label: customer?.customerName ?? '', value: customer?.customerNumber ?? ''}
    : {label: selectedPayer?.payerName ?? '', value: selectedPayer?.payerNumber ?? ''}

  const [selectedPayerOrCustomer, setSelectedPayerOrCustomer] = useState<Option | undefined>(
    selectedItem
  )

  const items = filter.searchType === SearchTypeKeys.customer ? customerItems : payerItems

  const lookupDropdownItems = enableCustomerByNameSearch ? customerSearchTermItems : items

  const renderCountriesDropdown = () => (
    <Box mt={2}>
      <LookupDropdown
        data-test-id="lookup-dropdown-toggle-country"
        className={classes.dropdownStyle}
        popperClassName={classes.popperStyle}
        label={t('invite.create.selectCountry')}
        items={countriesOfRoles.map((country) => ({label: country, value: country}))}
        value={filter.countryId ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFilter({
            ...filter,
            searchId: undefined,
            countryId: e.target.value
          })
        }}
        onSubmit={(value: string) => {
          setFilter({
            ...filter,
            searchId: undefined,
            searchType: undefined,
            countryId: value
          })
        }}
      />
    </Box>
  )

  useEffect(() => {
    const dropdownSelectedCustomer = allCustomers?.find(
      (customer) =>
        customer.customerName === selectedPayerOrCustomer?.label &&
        customer.customerNumber === selectedPayerOrCustomer?.value
    )
    if (dropdownSelectedCustomer) {
      localStorage.setItem(
        `${DEFAULT_CUSTOMER}-${loggedInUserId}`,
        JSON.stringify(dropdownSelectedCustomer)
      )
    }
  }, [selectedPayerOrCustomer])

  const handleDropdownDisplay = () => {
    const handleErrorText = () => {
      if (customerItems && customerItems.length === 0) {
        return t('invite.create.customersNotFound')
      }

      if (payerItems && payerItems.length === 0) {
        return t('invite.create.payersNotFound')
      }

      if (
        enableCustomerByNameSearch &&
        customSearchTermNotFound &&
        !isCustomerBySearchTermLoading &&
        !!customerSearchTermItems
      ) {
        return t('invite.create.customerNotFound')
      }

      return undefined
    }

    if (shouldFetchAllCustomersOrPayersForSalesAgent) {
      return (
        <LookupDropdown
          data-test-id="lookup-dropdown-toggle"
          className={classes.dropdownStyle}
          popperClassName={classes.popperStyle}
          showSubTitle={true}
          error={enableCustomerByNameSearch ? customSearchTermNotFound : customerNotFound}
          label={getLookupLabel()}
          items={lookupDropdownItems}
          helperText={handleErrorText()}
          value={filter.searchId ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter({
              ...filter,
              searchId: e.target.value
            })
          }
          onSubmit={(value: string) => {
            setFilter({
              ...filter,
              searchId: value
            })
          }}
        />
      )
    } else if (displayCustomerSimpleLookup) {
      return (
        <LookupDropdown
          data-test-id="lookup-dropdown-toggle"
          className={classes.dropdownStyle}
          popperClassName={classes.popperStyle}
          showSubTitle={true}
          error={enableCustomerByNameSearch ? customSearchTermNotFound : customerNotFound}
          label={getLookupLabel()}
          helperText={handleErrorText()}
          items={lookupDropdownItems}
          value={filter.searchId ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter({
              ...filter,
              searchId: e.target.value
            })
          }
          onSubmit={(value: string) => {
            setFilter({
              ...filter,
              searchId: value
            })
          }}
        />
      )
    } else {
      return (
        <SelectDropdown
          data-test-id="customer-dropdown"
          label={getLookupLabel()}
          className={classes.selectDropdown}
          showError={
            customerSearch
              ? enableCustomerByNameSearch
                ? customSearchTermNotFound
                : customerNotFound
              : payerNotFound
          }
          errorRender={() => <ErrorPaper variant="primaryDark" />}
          loading={isLoading}
          options={items}
          renderItem={(item: Item) => <SelectItem item={item} />}
          renderSubItem={(item: Item) => <SelectItem item={item} />}
          stringifyItem={(item: Item) => item.value}
          stringifySubItem={(item: Item) => item.label}
          renderChosenItem={(item: Item) => item.label}
          selectedItem={selectedPayerOrCustomer}
          identifierKey="value"
          onChange={(item: Item) => {
            setFilter({
              ...filter,
              searchId: item.value
            })
            setSelectedPayerOrCustomer(item)
          }}
          popoverStyle={{left: 0}}
        />
      )
    }
  }

  const renderLeftColumn = () => {
    return (
      <>
        <Typography className={classes.header} gutterBottom>
          {t('invite.create.permissionHeader')}
        </Typography>
        <Divider color="soft" />
        {countriesOfRoles.length > 1 ? renderCountriesDropdown() : null}
        {countriesOfRoles.length === 1 || (countriesOfRoles.length > 1 && filter.countryId) ? (
          <>
            {renderRadioButtonGroup()}
            {filter.searchType && (
              <Box mt={2} style={{position: 'relative'}}>
                {handleDropdownDisplay()}
                {renderCustomerOrPayerName()}
              </Box>
            )}
            {renderTogglesAndProjectsAndSites()}
          </>
        ) : null}
      </>
    )
  }

  const [shouldMakeStickyFullWidth, setShouldMakeStickyFullWidth] = useState(false)
  const [showLinkConfiguration, setShowLinkConfiguration] = useState(false)

  const {ref: stickyRef, entry} = useInView({threshold: 1})

  useEffect(() => {
    if (isTablet) {
      entry?.intersectionRatio === 1
        ? setShouldMakeStickyFullWidth(false)
        : setShouldMakeStickyFullWidth(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyRef, entry])

  useEffect(() => {
    showLinkConfiguration
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [showLinkConfiguration])

  const handleShowLinkConfiguration = (showConfig: boolean) => {
    window.scrollTo({top: 0, left: 0})
    setShowLinkConfiguration(showConfig)
  }

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const generateLinkStyle: CSSProperties = isMobile
    ? {
        background: '#FFFFFF',
        color: '#000000',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '85vh',
        padding: '0 32px',
        overflowY: 'scroll',
        zIndex: 10
      }
    : {
        position: !isTablet ? 'static' : 'absolute',
        right: 0,
        background: '#FFFFFF',
        paddingLeft: '50px',
        paddingRight: '50px',
        marginTop: '-35px',
        borderLeft: !isTablet ? 'none' : '1px solid #E8E8E8',
        width: '50%',
        zIndex: 10,
        height: !isTablet ? 'auto' : '100%',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.05)',
        overflowY: 'scroll'
      }

  return (
    <Content>
      <SubHeader>
        <TitleNav title={t('overview.title')} onClick={goToOverview} />
      </SubHeader>
      <Box width={'100%'}>
        <Page boxed data-test-id="invitation-page">
          <Paper
            elevation={4}
            style={{
              padding: isTablet ? '35px 24px' : '24px 48px',
              minHeight: '70vh',
              position: 'relative'
            }}
          >
            {!isMobile && !isDesktop && (
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  backgroundColor: '#FFFFFF',
                  opacity: showLinkConfiguration ? 0.8 : 0,
                  zIndex: showLinkConfiguration ? 10 : 0
                }}
              />
            )}
            {isTablet && showLinkConfiguration && (
              <ClickAwayListener onClickAway={() => handleShowLinkConfiguration(false)}>
                <Box style={generateLinkStyle}>
                  <CloseButton onClose={() => handleShowLinkConfiguration(false)} />
                  {renderRightColumn()}
                </Box>
              </ClickAwayListener>
            )}
            <Grid container spacing={5}>
              <Grid item md={7} xs={12}>
                {renderLeftColumn()}
              </Grid>
              <Grid item md={1} className={classes.divider}>
                <Box display="flex" justifyContent="center" height="100%">
                  <Divider color="soft" orientation="vertical" />
                </Box>
              </Grid>
              {!isTablet && (
                <Grid item md={4} xs={12}>
                  {renderRightColumn()}
                </Grid>
              )}
            </Grid>

            {isTablet &&
              filter.searchId &&
              (modalState.all || projects.length > 0 || sites.length > 0) && (
                <div
                  style={{
                    position: 'sticky',
                    bottom: '-1px',
                    paddingTop: 'calc(1em + 1px)',
                    width: shouldMakeStickyFullWidth ? '100vw' : '100%',
                    left: 0,
                    right: 0,
                    background: '#FFFFFF',
                    height: '110px',
                    marginLeft: shouldMakeStickyFullWidth ? '-64px' : 0,
                    marginRight: shouldMakeStickyFullWidth ? '-64px' : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: shouldMakeStickyFullWidth
                      ? '-1px 0px 5px rgba(0, 0, 0, 0.25)'
                      : 'none',
                    zIndex: shouldMakeStickyFullWidth ? 100 : 0
                  }}
                  ref={stickyRef}
                >
                  <Button
                    startIcon={
                      roleRequestMutation.data ? (
                        <Share fontSize="small" />
                      ) : (
                        <ArrowForward fontSize="small" />
                      )
                    }
                    data-test-id="generate-link"
                    className={
                      roleRequestMutation.data ? classes.linkDetailsButton : classes.linkButton
                    }
                    onClick={() => handleShowLinkConfiguration(true)}
                    fullWidth
                  >
                    {roleRequestMutation.data
                      ? t('invitations.viewLinkDetails')
                      : t('invitations.submitAndContinue')}
                  </Button>
                </div>
              )}
          </Paper>
        </Page>
      </Box>
    </Content>
  )
}
