import {User} from '@hconnect/apiclient'
import get from 'lodash/get'
import omit from 'lodash/omit'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {DEFAULT_CUSTOMER, DEFAULT_PAYER} from '../../App.constants'
import {
  removeSiteFilter as removeGlobalSiteFilter,
  selectSiteFilter as selectGlobalSiteFilter
} from '../../Pages/Order/Order.filters'
import {AppState} from '../../Root.store'
import {Payer, PayersStateType, setGlobalPayerFilter} from '../Payers'
import {
  CustomersPayers,
  selectCustomerFromPayer,
  selectPayerFromCustomer,
  selectPayers,
  selectSelectedPayerFilter
} from '../Payers/Payers.selectors'
import {selectSites} from '../Sites'
import {Site} from '../Sites/Sites.types'

import {CustomerStateType} from './Action.types'
import {setGlobalCustomerFilter} from './Customers.action'
import {selectCustomers, selectSelectedCustomerFilter} from './Customers.selector'
import {Customer} from './Customers.types'
import CustomersDropdown from './CustomersDropdown'
import {filterType, useTrackFilterEvents} from '../../TrackEvents/hubFilterEvents'
import {PageNames} from '../../common/constants'

interface CustomerFilterType {
  'data-test-id'?: string
  dark?: boolean
  long?: boolean
  onLight: boolean
  loading?: boolean
  showTitleOnlyEnabled?: boolean
  customersOnly?: boolean
  smallScreen?: boolean
  useHaulierView?: boolean
  page: PageNames
}

const CustomersFilter: React.FC<CustomerFilterType> = ({
  dark,
  long,
  onLight,
  loading,
  showTitleOnlyEnabled,
  customersOnly = false,
  smallScreen = false,
  useHaulierView = false,
  page,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {trackFilterEvents} = useTrackFilterEvents()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  // TODO: check if selectedCustomer should be deleted
  const {customers, selectedCustomer} = customersState
  const payersState = useSelector<AppState, PayersStateType>((state) => selectPayers(state))
  const selectedPayer = get(payersState, 'selectedPayer')
  const sites = useSelector<AppState, Site[]>((state) => get(selectSites(state), 'sites'))
  const siteId = useSelector<AppState, string>((state) =>
    get(selectGlobalSiteFilter(state), 'siteId')
  )
  const customerFilter = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )
  const payerFilter = useSelector<AppState, Payer | null>((state) =>
    selectSelectedPayerFilter(state)
  )
  // actions connected to store
  const setCustomerFilter = (customer: Customer) => dispatch(setGlobalCustomerFilter(customer))
  const setPayerFilter = (payer: Payer | null) => dispatch(setGlobalPayerFilter(payer))
  const removeSiteFilter = () => dispatch(removeGlobalSiteFilter())

  // local state
  const [defaultCustomer, setDefaultCustomer] = useState<Customer | null>(selectedCustomer)
  const [defaultPayer, setDefaultPayer] = useState<Payer | null>(selectedPayer)
  const [chosenValueType, setChosenValueType] = useState<'customer' | 'payer' | null>()

  const handleChangeDropdown = (value: CustomersPayers | Payer) => {
    let customer
    let payer
    const customerId = get(value, 'customerId')
    if (!customerId) {
      customer = selectCustomerFromPayer(value as Payer, customers)
      payer = value
      setChosenValueType('payer')
    } else {
      customer = omit(value, ['items'])
      payer = selectPayerFromCustomer(payersState, customerId)
      setChosenValueType('customer')
    }

    siteId &&
      !!sites.length &&
      !sites
        .filter((site: Site) => site.customerId === customer.customerId)
        .some((site: Site) => site.siteId === siteId) &&
      removeSiteFilter()
    // set the customer filter
    setDefaultCustomer(customer as Customer)
    user?.user_id &&
      localStorage.setItem(`${DEFAULT_CUSTOMER}-${user?.user_id}`, JSON.stringify(customer))
    setCustomerFilter(customer as Customer)
    // set the payer filter
    setDefaultPayer(payer)
    localStorage.setItem(DEFAULT_PAYER, JSON.stringify(payer))
    setPayerFilter(payer)
    trackFilterEvents({
      filterType: filterType.CUSTOMERS,
      page,
      customerId,
      cleared: !customerId
    })
  }

  useEffect(() => {
    if (!selectedCustomer) return
    const payer = selectPayerFromCustomer(payersState, selectedCustomer.customerId)

    if (payer?.payerId === selectedPayer?.payerId) return

    setDefaultPayer(payer)
    localStorage.setItem(DEFAULT_PAYER, JSON.stringify(payer))
    setPayerFilter(payer)
  }, [selectedCustomer, selectedPayer, payersState, setPayerFilter])

  return (
    <CustomersDropdown
      label={
        useHaulierView ? t('hauliers.haulierDropdown.label') : t('customers.customerDropdown.label')
      }
      dark={dark}
      long={long}
      onLight={onLight}
      onCustomerChange={handleChangeDropdown}
      chosenValueType={chosenValueType}
      data-test-id={dataTestId}
      loading={loading}
      showTitleOnlyEnabled={showTitleOnlyEnabled}
      customersOnly={customersOnly}
      t={t}
      smallScreen={smallScreen}
    />
  )
}

export default CustomersFilter
