import {trackEvent} from '@hconnect/common/logging/Analytics'
import {ErrorPaper, Typography} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import classNames from 'classnames'
import {TFunction} from 'i18next'
import {debounce, get, some} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {AppState} from '../../Root.store'
import {selectCustomers} from '../Customers'
import {Customer} from '../Customers/Customers.types'
import {Site, selectSites} from '../Sites'

import {getProjectsWithSites, removeProjectFilter, removeSiteFilter} from './Projects.action'
import {selectProjects} from './Projects.selectors'
import {Project, ProjectSitesDropdownSiteItem, ProjectWithSites} from './Projects.types'
import {SelectDropdown} from '../../Molecules/SelectDropdown/SelectDropdown'
import {getDropdownSelectedItem} from '../../Molecules/SelectDropdown/getDropdownSelectedItem'

const toAddress = (site: Site, linebreak = '\n', separator = ',') =>
  `${site.street ? site.street + linebreak : ''} 
   ${site.city ? site.city + separator : ''}
   ${site.state ? site.state + separator : ''} 
   ${site.postalCode || ''}`

const useStyles = makeStyles(() => ({
  button: {
    width: 222
  }
}))

interface SelectItemType {
  project: Project
  t: TFunction
  onLight?: boolean
}

const SelectItem: React.FC<SelectItemType> = ({project, t, onLight}) => {
  const {projectNumber} = project
  const id = projectNumber && projectNumber !== '0' ? projectNumber : undefined
  return (
    <Box aria-label={`Project filter option ${project.projectName}`}>
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {project.projectName.toUpperCase()}
      </Typography>
      <Typography
        variant="caption"
        color={onLight ? 'textPrimary' : 'textSecondary'}
        component="div"
      >
        {project.projectNumber}
      </Typography>
      {id && (
        <Typography
          component="div"
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('order.orderDropdown.id')} {id}
        </Typography>
      )}
    </Box>
  )
}

interface SelectSubItemType {
  site: Site
  t: TFunction
  onLight?: boolean
}

const SelectSubItem: React.FC<SelectSubItemType> = ({site, t, onLight}) => {
  const {siteNumber} = site
  const id = siteNumber && siteNumber !== '0' ? siteNumber : undefined
  return (
    <Box aria-label={`Site filter option ${site.siteName}`}>
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {site.siteName}
      </Typography>
      <Typography
        variant="caption"
        color={onLight ? 'textPrimary' : 'textSecondary'}
        component="div"
        dangerouslySetInnerHTML={{
          __html: toAddress(site, '<br />')
        }}
      />
      {id && (
        <Typography
          component="div"
          variant="caption"
          customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}
        >
          {t('order.orderDropdown.id')} {id}
        </Typography>
      )}
    </Box>
  )
}

interface ProjectsWithSitesDropdownType {
  projectsWithSites: ProjectWithSites[] | ProjectSitesDropdownSiteItem[]
  sitesIsFetching: boolean
  projectsIsFetching: boolean
  error: any
  getProjectsWithSites: (customerId: string) => void
  selectedProjectId: string
  selectedSiteId: string
  selectedCustomer: Customer | null
  removeProjectFilter: () => void
  removeSiteFilter: () => void
  customers: Customer[]
  props?: any
  label?: string
  noSelectionLabel: any
  onChange?: (value: ProjectWithSites) => void
  onLight?: boolean
}
const ProjectsWithSitesDropdown: React.FC<ProjectsWithSitesDropdownType> = ({
  projectsWithSites,
  sitesIsFetching,
  projectsIsFetching,
  error,
  getProjectsWithSites,
  selectedProjectId,
  selectedSiteId,
  selectedCustomer,
  removeProjectFilter,
  removeSiteFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customers,
  ...props
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [options, setOptions] = useState<ProjectWithSites[] | ProjectSitesDropdownSiteItem[]>(
    projectsWithSites
  )

  const isFetching = projectsIsFetching || sitesIsFetching

  useEffect(() => {
    if (!isFetching && selectedCustomer) {
      getProjectsWithSites(selectedCustomer.customerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer])

  useEffect(() => {
    if (selectedCustomer) {
      if (selectedProjectId && !some(projectsWithSites, {projectId: selectedProjectId})) {
        removeProjectFilter()
      } else if (
        selectedSiteId &&
        !some(
          [
            getDropdownSelectedItem({
              items: projectsWithSites,
              isSubItem: true,
              selectedItemId: selectedSiteId,
              itemKey: 'projectId',
              subItemKey: 'siteId'
            })
          ],
          {
            siteId: selectedSiteId
          }
        )
      ) {
        removeSiteFilter()
      }
      setOptions(projectsWithSites)
    } else {
      setOptions(projectsWithSites)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, projectsWithSites, selectedSiteId, selectedProjectId])

  const selectedItem =
    (selectedProjectId || selectedSiteId) && projectsWithSites
      ? getDropdownSelectedItem({
          items: projectsWithSites,
          isSubItem: !!selectedSiteId,
          selectedItemId: selectedProjectId || selectedSiteId,
          itemKey: 'projectId',
          subItemKey: 'siteId'
        })
      : null

  const {label, noSelectionLabel, onLight, ...restProps} = props

  const debounceTypeaheadSearchTerm = useCallback(
    debounce(
      (term) =>
        trackEvent('typeaheadSearchTerm', {
          product: 'hub',
          type: 'destination',
          searchTerm: term
        }),
      200
    ),
    []
  )

  return (
    <SelectDropdown
      data-test-id="sites-dropdown"
      selectedProps={{
        className: classNames(classes.button)
      }}
      label={label ?? ''}
      loading={isFetching}
      noSelectionLabel={noSelectionLabel}
      showError={!!error}
      errorRender={() => (
        <ErrorPaper
          variant="primaryDark"
          onRetry={() => selectedCustomer && getProjectsWithSites(selectedCustomer?.customerId)}
        />
      )}
      options={options}
      renderItem={(item: Project) => (
        <SelectItem
          project={item}
          t={t}
          onLight={onLight}
          data-test-id={`project-option-${item.projectId}`}
        />
      )}
      renderSubItem={(item: Site) => (
        <SelectSubItem
          site={item}
          t={t}
          onLight={onLight}
          data-test-id={`site-option-${item.siteId}`}
        />
      )}
      stringifyItem={(item: Project) => `${item.projectName} - ${item.projectNumber}`}
      stringifySubItem={(item: Site) =>
        `${item.siteName} ${item.street} ${item.postalCode} ${item.postalCode} ${item.city} ${item.siteNumber}`
      }
      selectedItem={selectedItem}
      identifierKey="siteId"
      SubItemIcon={LocationOnIcon}
      onLight={onLight}
      onSearchTermChange={(term: string) => term && debounceTypeaheadSearchTerm(term)}
      {...restProps}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  projectsWithSites: get(selectProjects(state), 'projectsWithSites'),
  sitesIsFetching: get(selectSites(state), 'isFetching'),
  projectsIsFetching: get(selectProjects(state), 'isFetching'),
  error: get(selectProjects(state), 'error'),
  selectedCustomer: get(selectCustomers(state), 'selectedCustomer'),
  customers: get(selectCustomers(state), 'customers')
})
const mapDispatchToProps = (dispatch) => ({
  getProjectsWithSites: (customerId: string) => dispatch(getProjectsWithSites(customerId)),
  removeProjectFilter: () => dispatch(removeProjectFilter()),
  removeSiteFilter: () => dispatch(removeSiteFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsWithSitesDropdown)
