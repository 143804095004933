import {KeyboardArrowDown} from '@mui/icons-material'
import {ButtonBase} from '@mui/material'
import React from 'react'
import {makeStyles} from 'tss-react/mui'
import {Typography} from './Typography'
import useDropdownStyle from './DropdownStyles'
import {TitleButtonProps} from './types'

export const useStyles = makeStyles()((theme) => ({
  button: {
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: '#f7f7f7',
    border: `1px solid #e7e7e7`,
    '&:hover': {
      background: '#e7e7e7',
      borderColor: '#eeeeee'
    }
  },
  long: {
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid #7f9ba6`,
    marginBottom: theme.spacing(0.16),
    marginLeft: theme.spacing(0.5)
  },
  highlighted: {
    border: `1px solid ${theme.palette.error.main}`,
    '&:hover': {
      borderColor: theme.palette.error.main
    }
  }
}))

export const TitleButton: React.FC<TitleButtonProps> = ({
  className,
  buttonRef,
  long = false,
  highlighted = false,
  dark,
  inWidget,
  smallScreen,
  label,
  selectedLabel,
  selectedSubLabel,
  selectedProps,
  isLoading,
  ...otherProps
}) => {
  const {classes: titleButtonClasses} = useStyles()
  const {classes: selectDropdownclasses} = useDropdownStyle()

  return (
    <ButtonBase
      className={
        long
          ? titleButtonClasses.long
          : `${titleButtonClasses.button} ${className} ${{
              [titleButtonClasses.highlighted]: highlighted
            }}`
      }
      ref={buttonRef}
      {...otherProps}
    >
      <div {...selectedProps}>
        <Typography
          variant="caption"
          customColor={dark ? 'textErrorContrastText' : 'textPrimarySoft'}
        >
          {label}
        </Typography>
        <div
          className={
            long && !inWidget
              ? selectDropdownclasses.textFieldLong
              : long && inWidget
                ? selectDropdownclasses.textFieldLongAndInWidget
                : selectDropdownclasses.textField
          }
        >
          <Typography
            variant={long && !inWidget ? 'h2' : 'subtitle1'}
            color={dark ? 'string' : 'textPrimary'}
            noWrap={!smallScreen}
          >
            {selectedLabel}
          </Typography>
          <KeyboardArrowDown
            className={long ? selectDropdownclasses.arrowWhite : selectDropdownclasses.arrow}
          />
        </div>
        {selectedSubLabel && !isLoading ? (
          <Typography
            variant="caption"
            customColor={dark ? 'textErrorContrastText' : 'textPrimarySoft'}
            noWrap
            style={{display: 'block'}}
          >
            {selectedSubLabel}
          </Typography>
        ) : null}
      </div>
    </ButtonBase>
  )
}
