import {Box, MenuItem, MenuList, Popover, Typography} from '@mui/material'
import Divider from './Divider'
import {SearchBox} from './SearchBox'
import React, {useRef} from 'react'
import {isEmpty, omit} from 'lodash'
import useDropdownStyle from './DropdownStyles'
import {SelectDropdownContentProps} from './types'
import DoneIcon from '@mui/icons-material/Done'

/**
 * Function which filters out options based on typed term
 *
 * @param options array of items which should be rendered as select options
 * @param stringifyFunc function that stringify the object
 * @param search search term
 * @param stringifySubFunc (optional) if dropdown has nested list, this function should be applied
 * @returns array of filtered items by search term
 *
 */
export const filteredOptions = (
  options: any[],
  search: string,
  stringifyFunc?: (o: any) => string,
  stringifySubFunc?: (o: any) => string
): any[] => {
  const res = options.filter((option: any) => {
    const normalizedOption = stringifyFunc ? stringifyFunc(option).toLowerCase() : ''
    const termFounded = search
      .toLowerCase()
      .split(/\s+/)
      .every((subTerm: string) => normalizedOption.includes(subTerm))
    if (stringifySubFunc && option.items && option.items.length > 0) {
      // be careful - recursion bellow
      const filteredSubItems = filteredOptions(option.items, search, stringifySubFunc)
      return termFounded || filteredSubItems.length > 0
    }
    return termFounded
  })
  return res
}

export const DropdownContent = ({
  popoverStyle,
  label,
  stringifySubItem,
  stringifyItem,
  dark,
  anchorEl,
  showError = false,
  showSearch = true,
  noSelectionLabel,
  errorRender = () => 'Something went wrong',
  onChange = () => undefined,
  options = [],
  SubItemIcon,
  onLight,
  t,
  renderItem,
  renderSubItem,
  dataTestId,
  selectedItem,
  open,
  isDisabled,
  setOpen,
  multiSelect = false,
  searchTerm,
  identifierKey = '',
  setSearchTerm
}: SelectDropdownContentProps) => {
  const menuListRef = useRef<HTMLUListElement>(null)
  const {classes} = useDropdownStyle()

  const menuItemClasses = {
    root: onLight
      ? `${classes.itemOnLight} ${classes.menuItem}`
      : `${classes.item} ${classes.menuItem}`,
    selected: onLight ? classes.selectedOnLight : classes.selected
  }
  const isMenuItemSelected = (option: any) => {
    return selectedItem
      ? typeof option === 'object'
        ? option[identifierKey] === selectedItem[identifierKey]
        : option === selectedItem
      : false
  }

  const handleEntered = () => {
    if (menuListRef.current && selectedItem) {
      let selectedIndex = -1

      for (const [index, option] of options.entries()) {
        if (option?.items?.length > 1) {
          const item = menuListRef.current.querySelector('#subDoneIcon')
          if (item) {
            item.scrollIntoView({behavior: 'smooth', block: 'center'})
            return
          }
        }

        if (isMenuItemSelected(option)) {
          selectedIndex = index
          break
        }
      }

      if (selectedIndex >= 0) {
        if (noSelectionLabel) selectedIndex = selectedIndex + 3 // +3 because search box is added on this condition
        const selectedItem = menuListRef.current.children[selectedIndex]
        selectedItem && selectedItem.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
    }
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl.current}
      onClose={() => setOpen(false)}
      TransitionProps={{onEntered: handleEntered}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      classes={{paper: onLight ? classes.popoverOnLight : classes.popover}}
      style={popoverStyle ? popoverStyle : {left: -20}}
    >
      {showError ? (
        errorRender()
      ) : (
        <>
          {showSearch && (
            <SearchBox
              classes={classes}
              label={t('dropdown.searchList')}
              dark={dark}
              onLight={onLight}
              t={t}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          )}
          <MenuList
            classes={{root: classes.menu}}
            aria-label={`${label} filter list`}
            aria-details={`${dataTestId}-filter-list`}
            data-test-id={`${dataTestId}-filter-list`}
            ref={menuListRef}
          >
            {noSelectionLabel && [
              <Divider key="divider" color={onLight ? 'onLight' : 'onBlue'} />,
              <MenuItem
                key="menuItem"
                classes={menuItemClasses}
                selected={!selectedItem}
                onClick={(e) => {
                  setOpen(multiSelect)
                  onChange(null)
                  setSearchTerm('')
                }}
              >
                <Typography
                  variant="body1"
                  color={onLight ? 'textPrimary' : 'textSecondary'}
                  component="span"
                  aria-label={`${label} filter option ${noSelectionLabel}`}
                >
                  {noSelectionLabel}
                </Typography>
              </MenuItem>
            ]}
            {options.length === 0 || !noSelectionLabel ? null : (
              <Divider color={onLight ? 'onLight' : 'onBlue'} />
            )}
            {filteredOptions(options, searchTerm, stringifyItem, stringifySubItem).map(
              (option: any, index: number) => {
                const isLastItem = index === options.length - 1
                const onlySubItems = isEmpty(omit(option, 'items'))
                const disabled = isDisabled ? isDisabled(option) : false
                const isItemSelected = isMenuItemSelected(option)

                return (
                  <Box
                    key={stringifyItem(option) + '_' + index}
                    display="flex"
                    flexDirection="column"
                    data-test-id={`dropdown-filter-option-${option?.value ? option?.value : index}`}
                    aria-label={`Site filter option ${index}`}
                  >
                    {!onlySubItems ? (
                      <MenuItem
                        classes={menuItemClasses}
                        onClick={(e) => {
                          setOpen(multiSelect)
                          onChange(option)
                        }}
                        selected={isItemSelected}
                        disabled={disabled}
                      >
                        <Typography variant="body1" color="textSecondary" component="span">
                          {renderItem(option)}
                        </Typography>
                        {isItemSelected && <DoneIcon />}
                      </MenuItem>
                    ) : null}
                    {(!isDisabled &&
                      renderSubItem &&
                      stringifySubItem &&
                      option.items &&
                      option.items.length > 0) ||
                    (disabled && option.items && option.items.length > 1)
                      ? filteredOptions(option.items, searchTerm, stringifySubItem).map(
                          (item: any, index: number) => {
                            return (
                              <React.Fragment
                                key={`${
                                  stringifySubItem ? stringifySubItem(item) : JSON.stringify(item)
                                }_${index}`}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                  aria-label={`Site filter option ${index}`}
                                >
                                  <MenuItem
                                    classes={menuItemClasses}
                                    onClick={(e) => {
                                      setOpen(multiSelect)
                                      onChange(item)
                                    }}
                                    selected={selectedItem === item}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="flex-start"
                                      justifyContent="flex-start"
                                      paddingLeft={onlySubItems ? 1 : 1.625}
                                    >
                                      {SubItemIcon && (
                                        <SubItemIcon
                                          style={{marginRight: 8, fontSize: 15, marginTop: 4}}
                                        />
                                      )}
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        component="span"
                                        classes={{root: classes.fontSize}}
                                      >
                                        {renderSubItem ? renderSubItem(item) : ''}
                                      </Typography>
                                    </Box>
                                    {selectedItem === item && <DoneIcon id="subDoneIcon" />}
                                  </MenuItem>
                                </Box>
                                {onlySubItems && <Divider color={onLight ? 'onLight' : 'onBlue'} />}
                              </React.Fragment>
                            )
                          }
                        )
                      : null}
                    {!isLastItem && <Divider color={onLight ? 'onLight' : 'onBlue'} />}
                  </Box>
                )
              }
            )}
          </MenuList>
        </>
      )}
    </Popover>
  )
}
