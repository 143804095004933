import {TabPanel as TabPanelMui} from '@material-ui/lab'
import {Box, ButtonBase, css, styled, Tab, Tabs} from '@mui/material'

const commonBorderStyle = ({theme}) => `1px solid ${theme.palette.grey[200]}`

export const CollapsedTabs = styled(Tabs)`
  & .MuiTabs-indicator {
  }
  & .MuiTab-root {
    border: none;
  }
  & .MuiTabs-scroller {
    overflow-y: visible;
    transform: translate(0, 1px);
  }
  & .MuiTabs-scrollButtons {
    display: none;
    @media (max-width: 1300px) and (min-width: 900px) {
      display: flex !important;
    }
  }
  overflow: visible;
  border-bottom: ${commonBorderStyle};
`

export const CollapsedLegacyTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    visibility: hidden;
  }
  & .MuiTabs-scroller {
    overflow-y: visible;
    transform: translate(0, 1px);
  }
  overflow: visible;
  border-bottom: ${commonBorderStyle};
`

export const CollapsedTab = styled(Tab)<{selected: boolean}>(
  ({theme, selected}) => css`
    border: none;
    transform: translate(0, 3px);
    min-width: 50px;
    ${selected &&
    css`
      border: ${commonBorderStyle({theme})};
      border-bottom: none;
      background-color: white;
      transform: none;
    `}
    & .MuiSvgIcon-root {
      vertical-align: text-bottom;
    }
    & .indicator {
      display: none;
      width: 7px;
      height: 7px;
      border-radius: ${theme.shape.borderRadius}px;
      border: none;
      margin-bottom: 8px;
      margin-left: 2px;
      &.error {
        display: inline-block;
        background-color: ${theme.palette.error.dark};
      }
      &.dirty {
        display: inline-block;
        background-color: ${theme.palette.warning.light};
      }
    }
  `
)

export const CollapsedLegacyTab = styled(Tab)<{selected: boolean}>(
  ({theme, selected}) => css`
    border: ${commonBorderStyle({theme})};
    border-bottom: none;
    border-top-left-radius: ${theme.shape.borderRadius}px;
    border-top-right-radius: ${theme.shape.borderRadius}px;
    transform: translate(0, 3px);
    min-width: 50px;
    ${selected &&
    css`
      border: ${commonBorderStyle({theme})};
      border-bottom: none;
      background-color: white;
      transform: none;
    `}
    & .MuiSvgIcon-root {
      vertical-align: text-bottom;
    }
    & .indicator {
      display: none;
      width: 7px;
      height: 7px;
      border-radius: ${theme.shape.borderRadius}px;
      border: none;
      margin-bottom: 8px;
      margin-left: 2px;
      &.error {
        display: inline-block;
        background-color: ${theme.palette.error.dark};
      }
      &.dirty {
        display: inline-block;
        background-color: ${theme.palette.warning.light};
      }
    }
  `
)

export const MobileTabs = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-left: ${({theme}) => theme.spacing(-2)};
  margin-right: ${({theme}) => theme.spacing(-2)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

export const MobileLegacyTabs = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  justify-content: space-between;
  margin-left: ${({theme}) => theme.spacing(-2)};
  margin-right: ${({theme}) => theme.spacing(-2)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

export const MobileTab = styled(Tab)<{selected: boolean}>(
  ({theme, selected}) => css`
    border: none;
    min-width: auto;
    max-width: auto;
    & .MuiTypography-root {
      ${selected &&
      css`
        font-weight: ${theme.typography.fontWeightMedium};
        color: ${theme.palette.primary.oldMain};
      `}
    }
  `
)

export const MobileLegacyTab = styled(Tab)<{selected: boolean}>(
  ({theme, selected}) => css`
    border: none;
    min-width: auto;
    max-width: auto;
    & .MuiTypography-root {
      ${selected &&
      css`
        font-weight: ${theme.typography.fontWeightMedium};
        color: ${theme.palette.primary.oldMain};
        font-size: ${theme.typography.h4.fontSize};
      `}
    }
  `
)

export const ContentBox = styled('div')`
  border: ${commonBorderStyle};
  display: flex;
  flex-direction: column;
  border-top: none;
  border-bottom-right-radius: ${({theme}) => theme.shape.borderRadius}px;
  border-bottom-left-radius: ${({theme}) => theme.shape.borderRadius}px;
  & > * {
    flex: 1 0 auto;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    border: none;
    padding: 0;
  }
`

export const ContentLegacyBox = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  & > * {
    flex: 1 0 auto;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    border: none;
    padding: 0;
  }
`

export const CloseButton = styled(ButtonBase)`
  position: absolute;
  right: 0px;
  top: 0px;
  color: ${({theme}) => theme.palette.primary.main};
  padding: ${({theme}) => theme.spacing(2)};
  z-index: 1000;
`

export const MaterialEditorCloseButton = styled(ButtonBase)`
  position: absolute;
  right: -25px;
  top: -25px;
  color: ${({theme}) => theme.palette.primary.main};
  padding: 0;
  z-index: 1000;
`

export const CloseLegacyButton = styled(ButtonBase)`
  position: absolute;
  right: 0;
  top: 0;
  color: ${({theme}) => theme.palette.primary.main};
  padding: ${({theme}) => theme.spacing(2)};
  z-index: 1000;
  ${({theme}) => theme.breakpoints.down('md')} {
    top: ${({theme}) => theme.spacing(-4.3)};
    right: ${({theme}) => theme.spacing(-2.3)};
  }
`

export const TabPanel = styled(TabPanelMui)`
  padding: 0;
  padding-top: 24px;
`

export const TabLegacyPanel = styled(TabPanelMui)`
  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`

export const ApplyButtonContainer = styled(Box)(({theme}) => {
  return css`
    background-color: ${theme.palette.grey[50]};
    border-top: ${commonBorderStyle({theme})};
    flex-grow: 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: ${theme.spacing(2)};
    position: sticky;
    bottom: 50px;
    border-bottom-left-radius: ${Number(theme.shape.borderRadius) + 1}px;
    border-bottom-right-radius: ${Number(theme.shape.borderRadius) + 1}px;
    & svg {
      margin-right: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down('md')} {
      margin-left: ${theme.spacing(-2)};
      margin-right: ${theme.spacing(-2)};
      margin-bottom: ${theme.spacing(-4)};
      bottom: ${theme.spacing(-1)};
    }
  `
})
