import React from 'react'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    background: 'none',
    color: theme.palette.primary.contrastText,
    width: '100%',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface ContentProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

const Content: React.FC<ContentProps> = ({children, className, ...props}) => {
  const {classes} = useStyles()
  return (
    <div className={`${classes.contentContainer} ${className}`} {...props}>
      {children}
    </div>
  )
}

export default Content
