import {makeStyles} from '@material-ui/core'

export const useAccountMenuStyles = makeStyles((theme) => ({
  activeMenuButton: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: 'none'
  },
  accountButton: {
    boxShadow: 'none',
    width: '40px',
    height: '40px'
  },
  overlayVisibility: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  tab: {
    textTransform: 'capitalize'
  },
  overlay: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingTop: '20px'
  },
  overlayContent: {
    position: 'relative',
    left: 0,
    transitionProperty: 'left',
    transitionDuration: '.5s'
  },
  menu: {
    borderRadius: '8px',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    display: 'inline-block',
    width: '100%',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)'
  },
  subMenu: {
    justifyContent: 'space-between'
  },
  subMenuList: {
    position: 'absolute',
    width: '360px',
    left: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '28px',
      left: 'initial'
    }
  },
  subMenuOpen: {
    [theme.breakpoints.down('xs')]: {
      left: '-96%'
    }
  },
  item: {
    color: '#57728d',
    padding: '18px 24px !important',
    '&.MuiMenuItem-root': {
      padding: '18px 24px',
      color: '#57728d'
    }
  },
  userIcon: {
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    lineHeight: '80px',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 500
  },
  name: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  email: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line'
  },
  accountsHeader: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px'
  },
  account: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  accountsElements: {
    margin: '24px'
  },
  flag: {
    width: 25,
    height: 16,
    marginRight: `${theme.spacing(0.5)}px`
  },
  icon: {
    marginRight: '8px',
    fill: '#54708C !important'
  },
  legalIcon: {
    margin: '0 8px',
    fill: '#54708C !important'
  },
  iconRight: {
    marginLeft: '8px'
  },
  logoutIcon: {
    color: '#DA0901'
  },
  carrot: {
    fontSize: 'inherit !important'
  },
  divider: {
    margin: '0 24px !important'
  },
  orange: {
    color: '#ff644e',
    backgroundColor: 'rgba(255,224,220) !important'
  },
  blue: {
    color: '#014a94',
    backgroundColor: 'rgba(128,180,223) !important'
  },
  green: {
    color: '#006e67',
    backgroundColor: 'rgba(128,206,201) !important'
  },
  purple: {
    color: '#641b9d',
    backgroundColor: 'rgba(199,146,239) !important'
  }
}))
